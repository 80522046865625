import { FETCH_COMPARES } from "../constants/actionTypes";

export default (compares = null, action) => {
  switch (action.type) {
    case FETCH_COMPARES:
      return action.compares;
    default:
      return compares;
  }
};
