import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import ComparePaper from "./ComparePaper";
import classes from "../search/styles.module.css";

const CompareMap = ({ compares }) => {
  const [googleMap, setGoogleMap] = useState(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [childClicked, setChildClicked] = useState(null);

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setGoogleMap(map);
      setGoogleMaps(maps);

      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      maps.event.clearListeners(map.data, "click");

      let geojsons = {
        type: "FeatureCollection",
        features: [],
      };
      if (compares?.length) {
        compares.map((compare, i) => {
          let geojson = {
            type: "Feature",
            properties: {
              index: 0,
              id: 0,
              parcel_id: "",
            },
            geometry: {},
          };
          if (compare.shape?.geometry) {
            geojson["properties"]["index"] = i;
            geojson["properties"]["id"] = compare.parcel.id;
            geojson["properties"]["parcel_id"] = compare.parcel.parcel_id;
            geojson["geometry"] = compare.shape.geometry;

            geojsons["features"].push(geojson);
          }
        });
        map.data.addGeoJson(geojsons);
        map.data.setStyle((feature) => {
          let index = feature.getProperty("index");
          let color = "gray";
          if (index === 0) {
            color = "blue";
          }

          return {
            fillColor: color,
            strokeWeight: 1,
          };
        });
        map.data.addListener("click", (event) => {
          setChildClicked(event.feature.getProperty("index"));
        });
        map.addListener("click", (event) => {
          setChildClicked(null);
        });

        let bounds = new maps.LatLngBounds();
        map.data.forEach(function (feature) {
          feature.getGeometry().forEachLatLng(function (latlng) {
            bounds.extend(latlng);
          });
        });
        map.fitBounds(bounds);
      }
    }
  };

  useEffect(() => {
    handleApiLoaded(googleMap, googleMaps);
  }, [compares]);

  return (
    <>
      {compares?.length ? (
        <div className={classes.parcelMapContainer}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            center={{
              lat: 0,
              lng: 0,
            }}
            defaultZoom={16}
            margin={[50, 50, 50, 50]}
            options={(maps) => ({
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
              },
            })}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {compares?.length && childClicked !== null && (
              <ComparePaper
                lat={Number(compares[childClicked]?.shape?.lat)}
                lng={Number(compares[childClicked]?.shape?.lng)}
                parcel={compares[childClicked]}
                className={classes.paper}
              ></ComparePaper>
            )}
          </GoogleMapReact>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompareMap;
