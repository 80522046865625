import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tabs, Tab } from "@mui/material";
import {
  ScatterChart,
  Scatter,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { getChart } from "../../api/chart";
import TabPanel from "../other/TabPanel";
import classes from "./styles.module.css";

function ChartHome({ state }) {
  const parcels = useSelector((state) => state.parcels);
  const [tab, setTab] = useState(0);
  const tabs = [
    "Parcel Count",
    "Parcel FMV",
    "Sqft & Sale",
    "Sqft & Sale/Sqft",
    "Sqft & FMV",
    "Sqft & FMV/Sqft",
    "Sales Ratio",
  ];
  const [count, setCount] = useState([]);
  const [value, setValue] = useState([]);
  const [fmv, setFmv] = useState([]);
  const [sale, setSale] = useState([]);
  const [ratio, setRatio] = useState([]);
  const colors = ["#1976d2", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (state) {
      getChart(state, parcels).then((data) => {
        if (data?.count) setCount(data.count);
        if (data?.value) setValue(data.value);
        if (data?.fmv) setFmv(data.fmv);
        if (data?.sale) setSale(data.sale);
        if (data?.ratio) setRatio(data.ratio);
      });
    }
  }, [parcels]);

  return (
    <Box sx={{ p: 2 }}>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        textColor="secondary"
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map((t, i) => (
          <Tab key={i} label={t} />
        ))}
      </Tabs>
      <TabPanel value={tab} index={0}>
        {count?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <BarChart className={classes.barChart} data={count}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" fontSize={10} />
                <YAxis stroke="#00C49F" />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  name="Number of Parcels in Different Property Uses"
                  dataKey="value"
                  fill="#00C49F"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {value?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <BarChart className={classes.barChart} data={value}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" fontSize={10} />
                <YAxis stroke="#1976d2" width={100} />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  name="Total Parcel FMV in Different Property Uses"
                  dataKey="value"
                  fill="#1976d2"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        {sale?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <ScatterChart>
                <CartesianGrid />
                <XAxis type="number" dataKey="area" name="sqft" unit=" sqft" />
                <YAxis
                  width={100}
                  type="number"
                  dataKey="sale_price"
                  name="sale"
                  stroke="#00C49F"
                />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Legend verticalAlign="top" height={36} />
                <Scatter
                  name="Correlation of Sqft and Sale Price (residential in last 5 years)"
                  data={sale}
                  fill="#00C49F"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={3}>
        {sale?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <ScatterChart className={classes.scatterChart}>
                <CartesianGrid />
                <XAxis type="number" dataKey="area" name="sqft" unit=" sqft" />
                <YAxis
                  width={100}
                  type="number"
                  dataKey="cost"
                  name="sale/sqft"
                  stroke="#1976d2"
                />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Legend verticalAlign="top" height={36} />
                <Scatter
                  name="Correlation of Sqft and Sale/Sqft (residential in last 5 years)"
                  data={sale}
                  fill="#1976d2"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={4}>
        {fmv?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <ScatterChart className={classes.scatterChart}>
                <CartesianGrid />
                <XAxis type="number" dataKey="area" name="sqft" unit=" sqft" />
                <YAxis
                  width={100}
                  type="number"
                  dataKey="fmv"
                  name="fmv"
                  stroke="#00C49F"
                />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Legend verticalAlign="top" height={36} />
                <Scatter
                  name="Correlation of Sqft and FMV (residential)"
                  data={fmv}
                  fill="#00C49F"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={5}>
        {fmv?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <ScatterChart className={classes.scatterChart}>
                <CartesianGrid />
                <XAxis type="number" dataKey="area" name="sqft" unit=" sqft" />
                <YAxis
                  width={100}
                  type="number"
                  dataKey="cost"
                  name="fmv/sqft"
                  stroke="#1976d2"
                />
                <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                <Legend verticalAlign="top" height={36} />
                <Scatter
                  name="Correlation of Sqft and FMV/Sqft (residential)"
                  data={fmv}
                  fill="#1976d2"
                />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={6}>
        {ratio?.length > 0 && (
          <div className={classes.chartContainer}>
            <ResponsiveContainer>
              <BarChart className={classes.barChart} data={ratio}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="range" />
                <YAxis stroke="#00C49F" />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Bar
                  name="Sales Ratio (residential FMV/Sale) Distribution"
                  dataKey="count"
                  fill="#00C49F"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </TabPanel>
    </Box>
  );
}

export default ChartHome;
