import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ReCAPTCHA from "react-google-recaptcha";
import Input from "./Input";
import classes from "./styles.module.css";
import { signin, signup, forget } from "../../actions/auth";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Form = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);
  const [isSignup, setIsSignup] = useState(false);
  const [isForget, setIsForget] = useState(false);
  const [form, setForm] = useState(initialState);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    if (isForget) {
      dispatch(forget(form, token, setSuccess, setError));
      setIsForget(false);
    } else if (isSignup) {
      dispatch(signup(form, token, navigate, setError));
    } else {
      dispatch(signin(form, token, navigate, setError));
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const switchMode = (e) => {
    switch (e.target.name) {
      case "signup":
        setIsSignup((prevIsSignup) => !prevIsSignup);
        break;
      case "signin":
        setIsSignup(false);
        setIsForget(false);
        break;
      case "forget":
        setIsForget(true);
        break;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar} sx={{ bgcolor: "#fff" }}>
          <LockOutlinedIcon color="primary" />
        </Avatar>
        <Typography component="h1" variant="h6">
          {isSignup ? "Sign Up" : "Sign In"}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {success && (
              <Grid item xs={12}>
                <Alert
                  severity="success"
                  onClose={() => {
                    setSuccess(null);
                  }}
                >
                  {success}
                </Alert>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  onClose={() => {
                    setError(null);
                  }}
                >
                  {error}
                </Alert>
              </Grid>
            )}
            {isSignup && !isForget && (
              <>
                <Input
                  name="firstName"
                  label="First Name"
                  handleChange={handleChange}
                  autoFocus
                  half
                  required={true}
                />
                <Input
                  name="lastName"
                  label="Last Name"
                  handleChange={handleChange}
                  half
                  required={true}
                />
              </>
            )}
            <Input
              name="email"
              label="Email Address"
              handleChange={handleChange}
              type="email"
              required={true}
            />
            {isSignup && !isForget && (
              <Input
                name="businessName"
                label="Business Name"
                handleChange={handleChange}
                required={false}
              />
            )}
            {!isForget && (
              <Input
                name="password"
                label="Password"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                handleShowPassword={handleShowPassword}
                required={true}
              />
            )}
            {isSignup && !isForget && (
              <Input
                name="confirmPassword"
                label="Repeat Password"
                handleChange={handleChange}
                type={showPassword ? "text" : "password"}
                required={true}
              />
            )}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            style={{ marginTop: "15px" }}
          >
            {isForget ? "Forget Password" : isSignup ? "Sign Up" : "Sign In"}
          </Button>
          {isForget ? (
            <Grid container display="flex" justifyContent="center">
              <Grid item>
                <Button name="signin" onClick={(e) => switchMode(e)}>
                  {"Back to sign in"}
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              {!isSignup && (
                <Grid container display="flex" justifyContent="center">
                  <Grid item>
                    <Button name="forget" onClick={(e) => switchMode(e)}>
                      {"Forget your password?"}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid container display="flex" justifyContent="center">
                <Grid item>
                  <Button name="signup" onClick={(e) => switchMode(e)}>
                    {isSignup
                      ? "Already have an account? Sign in"
                      : "Don't have an account? Sign Up"}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </form>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      </Paper>
    </Container>
  );
};

export default Form;
