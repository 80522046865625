import axios from "axios";

const api = axios.create({ baseURL: "/api" });

export const createDonation = async (post) => {
  try {
    const {
      data: { donation },
    } = await api.post("/donation", post);

    return donation;
  } catch (err) {
    console.log(err);
  }
};
