import { Container, Grid, Box, Link } from "@mui/material";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

const Footer = () => {
  const url = "https://www.assessorinfo.org";

  return (
    <Box
      mt={5}
      px={{ xs: 3, sm: 8 }}
      pt={{ xs: 5, sm: 7 }}
      bgcolor="primary.dark"
      color="white"
      style={{ position: "relative", left: 0, bottom: 0, right: 0 }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3}>
            <Box
              mb={2}
              sx={{
                typography: "h6",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Business
            </Box>
            {/* <Box>
              <Link href="/data" underline="none" color="inherit">
                Data
              </Link>
            </Box> */}
            <Box>
              <Link href="/participate" underline="none" color="inherit">
                Participate
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              mb={2}
              sx={{
                typography: "h6",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Explore
            </Box>
            <Box>
              <Link href="/about" underline="none" color="inherit">
                About
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              mb={2}
              sx={{
                typography: "h6",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Support
            </Box>
            <Box>
              <Link href="/contact" underline="none" color="inherit">
                Contact us
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              mb={2}
              sx={{
                typography: "h6",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              Share
            </Box>
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <FacebookShareButton
                    url={url}
                    quote={"title goes here"}
                    hashtag={"#assessorinfo"}
                  >
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>
                </Grid>
                <Grid item xs={3}>
                  <TwitterShareButton
                    url={url}
                    title={"title"}
                    hashtags={["#assessorinfo"]}
                  >
                    <TwitterIcon size={32} round={true} />
                  </TwitterShareButton>
                </Grid>
                <Grid item xs={3}>
                  <LinkedinShareButton
                    url={url}
                    title={"title"}
                    summary={"summary"}
                    source={"assessorinfo"}
                  >
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </Grid>
                <Grid item xs={3}>
                  <EmailShareButton
                    url={url}
                    subject={"subject"}
                    body={"Please visit this site"}
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" pt={{ xs: 5, sm: 10 }} pb={{ xs: 8, sm: 6 }}>
          Copyright AssessorInfo.org &reg; {new Date().getFullYear()}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
