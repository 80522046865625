import axios from "axios";

const api = axios.create({ baseURL: "/api" });

export const sendMessage = async (form, token) => {
  form.token = token;
  const {
    data: { sent },
  } = await api.post("/contact", form);

  return sent;
};
