import axios from "axios";

const api = axios.create({ baseURL: "/api/parcels" });

api.interceptors.request.use((req) => {
  if (localStorage.getItem("assessorinfoprofile")) {
    req.headers.user = `Bearer ${
      JSON.parse(localStorage.getItem("assessorinfoprofile")).result.id
    }`;
  }

  return req;
});

export const fetchParcels = (getParams) => api.get("", { params: getParams });
