import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Box, Tabs, Tab, Container, Paper } from "@mui/material";
import Donation from "../donation/Donation";
import SearchForm from "../search/SearchForm";
import SearchMap from "../search/SearchMap";
import SearchList from "../search/SearchList";
import ChartHome from "../chart/ChartHome";
import CompareList from "../compare/CompareList";
import TabPanel from "../other/TabPanel";
import CookieBanner from "../other/CookieBanner";
import Cookies from "js-cookie";
import { getParcels } from "../../actions/parcels";
import { getCompare } from "../../actions/compare";
import classes from "./styles.module.css";

const SearchHome = ({ searchParams }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));
  const searchCookies = Cookies.get("search")
    ? JSON.parse(Cookies.get("search"))
    : {};
  const [search, setSearch] = useState(
    searchParams.state && searchParams.coordinates
      ? searchParams
      : searchParams.state &&
        searchCookies.state &&
        searchParams.state === searchCookies.state &&
        searchCookies.coordinates
      ? searchCookies
      : searchParams
  );
  const [bounds, setBounds] = useState({});
  const [coordinates, setCoordinates] = useState(
    search.coordinates
      ? search.coordinates
      : searchCookies.coordinates
      ? searchCookies.coordinates
      : {}
  );
  const [zoom, setZoom] = useState(
    search.zoom ? search.zoom : searchCookies.zoom ? searchCookies.zoom : 14
  );
  const [searchFields, setSearchFields] = useState({
    state: search.state,
    county: search.county ? search.county : "",
    address: search.address ? search.address : "",
    parcelId: search.parcelId ? search.parcelId : "",
    stNo: search.stNo ? search.stNo : "",
    stNoFrom: search.stNoFrom ? search.stNoFrom : "",
    stNoTo: search.stNoTo ? search.stNoTo : "",
    stDir: search.stDir ? search.stDir : "",
    stName: search.stName ? search.stName : "",
    stType: search.stType ? search.stType : "",
    city: search.city ? search.city : "",
    propertyUse: search.propertyUse ? search.propertyUse : "",
    taxDist: search.taxDist ? search.taxDist : "",
    subd: search.subd ? search.subd : "",
    nbhd: search.nbhd ? search.nbhd : "",
    township: search.township ? search.township : "",
    range: search.range ? search.range : "",
    section: search.section ? search.section : "",
    ownerName: search.ownerName ? search.ownerName : "",
    ownerAddress: search.ownerAddress ? search.ownerAddress : "",
    ownerCity: search.ownerCity ? search.ownerCity : "",
    ownerState: search.ownerState ? search.ownerState : "",
    ownerZip: search.ownerZip ? search.ownerZip : "",
    saleFrom: search.saleFrom ? search.saleFrom : "",
    saleTo: search.saleTo ? search.saleTo : "",
    priceFrom: search.priceFrom ? search.priceFrom : "",
    priceTo: search.priceTo ? search.priceTo : "",
    grantor: search.grantor ? search.grantor : "",
    grantee: search.grantee ? search.grantee : "",
    impType: search.impType ? search.impType : "",
    areaFrom: search.areaFrom ? search.areaFrom : "",
    areaTo: search.areaTo ? search.areaTo : "",
    bedFrom: search.bedFrom ? search.bedFrom : "",
    bedTo: search.bedTo ? search.bedTo : "",
    bathFrom: search.bathFrom ? search.bathFrom : "",
    bathTo: search.bathTo ? search.bathTo : "",
    roomFrom: search.roomFrom ? search.roomFrom : "",
    roomTo: search.roomTo ? search.roomTo : "",
    landUnit: search.landUnit ? search.landUnit : "",
    landFrom: search.landFrom ? search.landFrom : "",
    landTo: search.landTo ? search.landTo : "",
  });
  const [isMapLoading, setIsMapLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [childClicked, setChildClicked] = useState(null);
  const [donation, setDonation] = useState(
    Cookies.get("donation") ? Cookies.get("donation") : "yes"
  );
  const [tab, setTab] = useState(0);
  const tabs = user ? ["results", "charts", "compare"] : ["results", "charts"];

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  useEffect(() => {
    if (!searchParams.state) {
      setTimeout(() => {
        navigate("/error");
      });
    }

    if ((!bounds.sw || !bounds.ne) && (!coordinates.lat || !coordinates.lng)) {
      if (search?.state) {
        let stateCoordiantes = {};
        switch (search?.state) {
          case "AR":
            stateCoordiantes = { lat: 34.7445, lng: -92.288 };
          case "CO":
            stateCoordiantes = { lat: 39.7392, lng: -104.9903 };
          case "DC":
            stateCoordiantes = { lat: 38.9072, lng: -77.0369 };
          case "FL":
            stateCoordiantes = { lat: 30.4383, lng: -84.2807 };
          case "MT":
            stateCoordiantes = { lat: 46.5891, lng: -112.0391 };
          case "OK":
            stateCoordiantes = { lat: 35.0078, lng: -97.0929 };
          case "PA":
            stateCoordiantes = { lat: 39.9526, lng: -75.1652 };
          case "TX":
            stateCoordiantes = { lat: 30.2672, lng: -97.7431 };
          case "WA":
            stateCoordiantes = { lat: 47.6062, lng: -122.3321 };
          case "WI":
            stateCoordiantes = { lat: 43.0722, lng: -89.4008 };
          case "WY":
            stateCoordiantes = { lat: 41.14, lng: -104.8202 };
          default:
            stateCoordiantes = { lat: 37.0902, lng: -95.7129 };
        }
        setCoordinates(
          search.coordinates ? search.coordinates : stateCoordiantes
        );
        setZoom(search.zoom ? search.zoom : 6);
      } else {
        setCoordinates({ lat: 37.0902, lng: -95.7129 });
        // TODO - for user current location later
        // navigator.geolocation.getCurrentPosition(
        //   ({ coords: { latitude, longitude } }) => {
        //     setCoordinates({ lat: latitude, lng: longitude });
        //   }
        // );
      }
    }

    if (user?.result?.id && search?.state) {
      dispatch(getCompare(user.result.id, search.state));
    }
  }, []);

  useEffect(() => {
    if (search.state && !search.id) {
      try {
        let query = search;
        Object.keys(query).map((key) => {
          if (!query[key]) delete query[key];
        });
        let params = JSON.stringify(query);
        navigate(`/search/${params}`);
        dispatch(getParcels(search));
        setIsListLoading(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [search]);

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.containerPaper} elevation={3}>
        {donation === "yes" && <Donation setDonation={setDonation} />}
        <SearchForm
          setSearch={setSearch}
          setSearchFields={setSearchFields}
          setTab={setTab}
          search={search}
          searchFields={searchFields}
        />
        <Grid container spacing={2} style={{ width: "100%" }}>
          <Grid item xs={12} md={6} style={{ height: "575px" }}>
            <SearchMap
              setBounds={setBounds}
              setCoordinates={setCoordinates}
              setZoom={setZoom}
              setChildClicked={setChildClicked}
              setIsMapLoading={setIsMapLoading}
              setSearchFields={setSearchFields}
              coordinates={coordinates}
              zoom={zoom}
              searchFields={searchFields}
              childClicked={childClicked}
              isMapLoading={isMapLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: "24px", height: "575px" }}
          >
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs value={tab} onChange={handleChangeTab} centered>
                {tabs.map((t, i) => (
                  <Tab key={i} label={t} />
                ))}
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              <SearchList
                setCoordinates={setCoordinates}
                setZoom={setZoom}
                setIsListLoading={setIsListLoading}
                setChildClicked={setChildClicked}
                isListLoading={isListLoading}
                state={search?.state}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <ChartHome state={search?.state} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <CompareList state={search?.state} />
            </TabPanel>
          </Grid>
        </Grid>
        <CookieBanner />
      </Paper>
    </Container>
  );
};

export default SearchHome;
