import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Auth from "./routes/Auth";
import Profile from "./routes/Profile";
import Map from "./routes/Map";
import Parcel from "./routes/Parcel";
import Compare from "./routes/Compare";
import Footer from "./components/other/Footer";
import Data from "./routes/Data";
import Participate from "./routes/Participate";
import About from "./routes/About";
import Privacy from "./routes/Privacy";
import Contact from "./routes/Contact";
import Error from "./routes/Error";
import ParcelError from "./components/parcel/ParcelError";
import classes from "./styles.module.css";

const App = () => {
  return (
    <>
      <div className={classes.contentContainer}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/auth" element={<Auth />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/search/:params" element={<Map />} />
            <Route exact path="/parcel/:params" element={<Parcel />} />
            <Route exact path="/parcel/error" element={<ParcelError />} />
            <Route exact path="/compare/:params" element={<Compare />} />
            <Route exact path="/data" element={<Data />} />
            <Route exact path="/participate" element={<Participate />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route path="/*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </>
  );
};

export default App;
