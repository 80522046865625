import { FETCH_COMPARES, VIEW_COMPARES } from "../constants/actionTypes";
import * as api from "../api/compares";

export const getCompares = (user_id, state_code) => async (dispatch) => {
  try {
    const { data } = await api.fetchCompares({ user_id, state_code });

    dispatch({ type: FETCH_COMPARES, compares: data.compares });
  } catch (error) {
    console.log(error.message);
  }
};

export const viewCompares = (user_id, state_code) => async (dispatch) => {
  try {
    const { data } = await api.viewCompares({ user_id, state_code });

    dispatch({ type: VIEW_COMPARES, compareDetail: data.compareDetail });
  } catch (error) {
    console.log(error.message);
  }
};
