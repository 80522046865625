import axios from "axios";

const api = axios.create({ baseURL: "/api/compares" });

api.interceptors.request.use((req) => {
  if (localStorage.getItem('assessorinfoprofile')) {
    req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('assessorinfoprofile')).token}`;
  }

  return req;
});

export const fetchCompares = (getParams) => api.get('', {params: getParams});

export const viewCompares = (getParams) => api.get('/detail', {params: getParams});