import React from "react";
import { Button, Grid } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";

const PrintIcon = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <Grid
      container
      justifyContent="flex-end"
      sx={{ marginTop: "5px", paddingRight: "10px" }}
    >
      <Button onClick={handlePrint} startIcon={<PrintOutlinedIcon />}>
        Print
      </Button>
    </Grid>
  );
};

export default PrintIcon;
