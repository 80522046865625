import React, { useState } from "react";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

const SearchToolBar = (props) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  return (
      <GridToolbarContainer>
      <Grid container>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarExport
            disabled={!checked}
            csvOptions={{ fileName: "AssessorInfoExport" }}
          />
        </Grid>
        <Grid item xs={6} sm={4.5}>
          <FormGroup sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  size="small"
                  onChange={handleChange}
                />
              }
              label={
                <FormHelperText sx={{ fontSize: 13, mt: -0.1 }}>
                  {"I agree "}
                  <a href="https://s3.amazonaws.com/assessorinfo.org/Terms+of+Service+Agreement.pdf" target={"_blank"} style={{ color: "inherit" }}>
                    Export Policy
                  </a>
                </FormHelperText>
              }
            />
          </FormGroup>
        </Grid>
    </Grid>
      </GridToolbarContainer>
  );
};

export default SearchToolBar;
