import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Alert, AlertTitle } from "@mui/material";
import PrintIcon from "../other/PrintIcon";
import CompareDetail from "./CompareDetail";
import CompareMap from "./CompareMap";

const CompareInfo = () => {
  const compareDetail = useSelector((state) => state.compareDetail);
  const [subject, setSubject] = useState(null);
  const [compare1, setCompare1] = useState(null);
  const [compare2, setCompare2] = useState(null);
  const [compare3, setCompare3] = useState(null);
  const [compare4, setCompare4] = useState(null);
  const [compare5, setCompare5] = useState(null);

  useEffect(() => {
    if (compareDetail?.compares?.length > 0) {
      setSubject(compareDetail.compares[0]);
      setCompare1(compareDetail.compares[1]);
      setCompare2(compareDetail.compares[2]);
      setCompare3(compareDetail.compares[3]);
      setCompare4(compareDetail.compares[4]);
      setCompare5(compareDetail.compares[5]);
    }
  }, [compareDetail]);

  return (
    <div style={{width: "100%"}}>
      {compareDetail?.compares?.length ? (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PrintIcon />
          </Grid>
          <Grid item xs={12}>
            <CompareDetail
              subject={subject}
              compare1={compare1}
              compare2={compare2}
              compare3={compare3}
              compare4={compare4}
              compare5={compare5}
            />
          </Grid>
          <Grid item xs={12}>
            <CompareMap compares={compareDetail.compares} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Alert severity="info" sx={{ width: "50vw", mt: "20px" }}>
              <AlertTitle>Warning</AlertTitle>
              No parcel added to compare
            </Alert>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CompareInfo;
