import React from "react";
import { CssBaseline, Grid, Alert, AlertTitle } from "@mui/material";
import HomeHeader from "../components/home/HomeHeader";

const Error = () => {
  return (
    <div>
      <CssBaseline />
      <HomeHeader />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Alert severity="warning" sx={{ width: "50vw", mt: "20px" }}>
            <AlertTitle>Warning</AlertTitle>
            Wrong page, there is nothing here. —{" "}
            <strong>
              <a href="/">GO HOME</a>
            </strong>
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default Error;
