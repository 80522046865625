import axios from "axios";

const api = axios.create({ baseURL: "/api/user" });

api.interceptors.request.use((req) => {
  if (localStorage.getItem("assessorinfoprofile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("assessorinfoprofile")).token
    }`;
  }

  return req;
});

export const signIn = (form) => api.post("/signin", form);
export const signUp = (form) => api.post("/signup", form);
export const forget = (form) => api.post("/forget", form);
