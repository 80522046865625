import axios from "axios";

const api = axios.create({ baseURL: "/api" });

export const getOptions = async (option, params) => {
  try {
    if (option === "state") {
      const {
        data: { states },
      } = await api.get("/states");

      return states;
    }

    if (option === "county") {
      const {
        data: { counties },
      } = await api.get("/counties", { params: params });

      return counties;
    }

    if (option === "location") {
      const {
        data: { locations },
      } = await api.get("/locations", { params: params });

      return locations;
    }
  } catch (err) {
    console.log(err);
  }
};
