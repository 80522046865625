import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Link,
  Alert,
  AlertTitle,
  Modal,
  Tooltip,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchToolBar from "./SearchToolBar";
import SearchModal from "./SearchModal";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import {
  createCompare,
  updateCompare,
  deleteCompare,
} from "../../actions/compare";
import classes from "./styles.module.css";

const SearchList = ({
  setCoordinates,
  setZoom,
  setIsListLoading,
  setChildClicked,
  isListLoading,
  state,
}) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));
  const parcels = useSelector((state) => state.parcels);
  const compare = useSelector((state) => state.compare);
  const [pid, setPid] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [noSubjectAlert, setNoSubjectAlert] = useState(false);
  const [hasSubjectAlert, setHasSubjectAlert] = useState(false);
  const [maxAlert, setMaxAlert] = useState(false);
  const [signinAlert, setSigninAlert] = useState(false);

  const getCountyState = (params) => {
    return `${params.row.county_name || " "} ${params.row.state_code || ""}`;
  };

  const handleCloseSigninAlert = () => {
    setSigninAlert(false);
  };

  const handleCloseNoSubjectAlert = () => {
    setNoSubjectAlert(false);
  };

  const handleCloseHasSubjectAlert = () => {
    setHasSubjectAlert(false);
  };

  const handleCloseMaxAlert = () => {
    setMaxAlert(false);
  };

  const columns = [
    {
      field: "map",
      headerName: "Map",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          if (params.row.lat) {
            setCoordinates({
              lat: Number(params.row.lat),
              lng: Number(params.row.lng),
            });
            setZoom(18);
          }
        };

        return (
          <Tooltip title="Zoom to Parcel">
            <IconButton size="small" disableRipple={true} onClick={onClick}>
              <LocationOnOutlinedIcon
                color={params.row.lat ? "primary" : "disabled"}
              />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClickAdd = (e) => {
          dispatch(
            createCompare({
              state_code: state,
              pid: params.row.id,
            })
          );
        };

        const onClickDelete = (e) => {
          dispatch(deleteCompare(compare?.id));
        };

        const onClickSignIn = (e) => {
          setSigninAlert(true);
        };

        const onClickHasSubject = (e) => {
          setHasSubjectAlert(true);
        };

        return user?.result ? (
          compare?.id ? (
            compare?.subject_id === params.row.id ? (
              <Tooltip title="Delete Compares">
                <IconButton
                  size="small"
                  disableRipple={true}
                  onClick={onClickDelete}
                >
                  <RemoveOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Has Subject">
                <IconButton
                  size="small"
                  disableRipple={true}
                  onClick={onClickHasSubject}
                >
                  <AddOutlinedIcon color="disabled" />
                </IconButton>
              </Tooltip>
            )
          ) : (
            <Tooltip title="Add as Subject">
              <IconButton
                size="small"
                disableRipple={true}
                onClick={onClickAdd}
              >
                <AddOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )
        ) : (
          <Tooltip title="Sign in">
            <IconButton
              size="small"
              disableRipple={true}
              onClick={onClickSignIn}
            >
              <AddOutlinedIcon color="disabled" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "compare",
      headerName: "Compare",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClickAdd = (e) => {
          if (compare?.id) {
            if (compare.compare_ids?.length === 5) {
              setMaxAlert(true);
            } else {
              dispatch(
                updateCompare(compare.id, {
                  field: "compare",
                  pid: params.row.id,
                })
              );
            }
          }
        };

        const onClickRemove = (e) => {
          dispatch(deleteCompare(compare?.id, { pid: params.row.id }));
        };

        const onClickSignIn = (e) => {
          setSigninAlert(true);
        };

        const onClickNoSubject = (e) => {
          setNoSubjectAlert(true);
        };

        return user?.result ? (
          compare?.id ? (
            compare?.compare_ids?.includes(params.row.id) ? (
              <Tooltip title="Remove Compare">
                <IconButton
                  size="small"
                  disableRipple={true}
                  onClick={onClickRemove}
                >
                  <RemoveOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add as Compare">
                <IconButton
                  size="small"
                  disableRipple={true}
                  onClick={onClickAdd}
                >
                  <AddOutlinedIcon
                    color={
                      compare?.compare_ids?.length === 5
                        ? "disabled"
                        : "primary"
                    }
                  />
                </IconButton>
              </Tooltip>
            )
          ) : (
            <Tooltip title="No Subject">
              <IconButton
                size="small"
                disableRipple={true}
                onClick={onClickNoSubject}
              >
                <AddOutlinedIcon color="disabled" />
              </IconButton>
            </Tooltip>
          )
        ) : (
          <Tooltip title="Sign in">
            <IconButton
              size="small"
              disableRipple={true}
              onClick={onClickSignIn}
            >
              <AddOutlinedIcon color="disabled" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 55,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          setPid(params.row.id);
          setOpenModal(true);
        };

        return (
          <Tooltip title="View Parcel Detail">
            <IconButton size="small" disableRipple={true} onClick={onClick}>
              <VisibilityOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "more",
      headerName: "More",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 55,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="View in New Window">
            <Link
              href={`/parcel/${JSON.stringify({
                state: params.row.state_code,
                id: params.row.id,
              })}`}
              target="_blank"
            >
              <IconButton size="small" disableRipple={true}>
                <MoreHorizOutlinedIcon color="primary" />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "county_state",
      headerName: "County State",
      minWidth: 150,
      flex: 1,
      valueGetter: getCountyState,
    },
    { field: "parcel_id", headerName: "Parcel ID", minWidth: 200, flex: 1 },
    {
      field: "alt_parcel_id",
      headerName: "Alt Parcel ID",
      minWidth: 200,
      flex: 1,
    },
    { field: "address", headerName: "Address", minWidth: 200, flex: 1 },
    {
      field: "acres",
      headerName: "Land Acres",
      type: "number",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "imp_sqft",
      headerName: "Building Sqft",
      type: "number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "property_use",
      headerName: "Property Use",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tax_district",
      headerName: "Tax District",
      minWidth: 150,
      flex: 1,
    },
    { field: "subdivision", headerName: "Subdivision", minWidth: 300, flex: 1 },
    {
      field: "neighborhood",
      headerName: "Neighborhood",
      minWidth: 200,
      flex: 1,
    },
    { field: "trs", headerName: "Twp-Rng-Sec", minWidth: 120, flex: 1 },
    { field: "owner_name", headerName: "Owner Name", minWidth: 300, flex: 1 },
    {
      field: "owner_address",
      headerName: "Owner Address",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "sale_date",
      headerName: "Last Sale Date",
      type: "date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "sale_price",
      headerName: "Last Sale Price",
      type: "number",
      minWidth: 150,
      flex: 1,
    },
  ];

  useEffect(() => {
    setChildClicked(null);
    setIsListLoading(false);

    setRows([]);

    parcels?.length &&
      parcels?.map((parcel, i) => {
        setRows((rows) => [
          ...rows,
          {
            id: parcel.id,
            state_code: parcel.state_code,
            county_name: parcel.county_name,
            parcel_id: parcel.parcel_id,
            alt_parcel_id: parcel.alt_parcel_id,
            address: parcel.address,
            acres: parcel.acres ? Number(parcel.acres) : null,
            imp_sqft: parcel.imp_sqft ? Number(parcel.imp_sqft) : null,
            property_use: parcel.property_use,
            tax_district: parcel.school_district_name,
            subdivision: parcel.subdivision_name,
            neighborhood: parcel.neighborhood_name,
            trs: parcel.trs,
            owner_name: parcel.owner_name,
            owner_address: parcel.owner_address,
            sale_date: parcel.sale_date
              ? parcel.sale_date.substring(0, 10)
              : null,
            sale_price: parcel.sale_price ? Number(parcel.sale_price) : null,
            lat: parcel.lat,
            lng: parcel.lng,
          },
        ]);
      });
  }, [parcels]);

  return (
    <div className={classes.container}>
      {isListLoading ? (
        <div className={classes.loading}>
          <CircularProgress size="5rem" />
        </div>
      ) : (
        <div className={classes.list}>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            rowHeight={50}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10]}
            pagination
            initialState={{
              sorting: {
                sortModel: [{ field: "parcel_id", sort: "asc" }],
              },
              columns: {
                columnVisibilityModel: {
                  subject: user?.result ? true : false,
                  compare: user?.result ? true : false,
                  alt_parcel_id: false,
                  acres: false,
                  imp_sqft: false,
                  property_use: false,
                  tax_district: false,
                  subdivision: false,
                  neighborhood: false,
                  trs: false,
                  owner_address: false,
                },
              },
            }}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            density="compact"
            components={{
              Toolbar: SearchToolBar,
            }}
          />
        </div>
      )}
      <SearchModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        state={state}
        pid={pid}
      />
      <Modal
        open={hasSubjectAlert}
        onClose={handleCloseHasSubjectAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Alert
          severity="info"
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <AlertTitle>Warning</AlertTitle>
          Please delete current subject by removing subject in Results or
          Compares before adding another property as subject.
        </Alert>
      </Modal>
      <Modal
        open={noSubjectAlert}
        onClose={handleCloseNoSubjectAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Alert
          severity="info"
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <AlertTitle>Warning</AlertTitle>
          Please select a property as subject before adding a compare property.
        </Alert>
      </Modal>
      <Modal
        open={maxAlert}
        onClose={handleCloseMaxAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Alert
          severity="info"
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <AlertTitle>Warning</AlertTitle>
          Max 5 parcels can be added as compare. Please remove compares before
          adding.
        </Alert>
      </Modal>
      <Modal
        open={signinAlert}
        onClose={handleCloseSigninAlert}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Alert
          severity="info"
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <AlertTitle>Warning</AlertTitle>
          Please Login before using compare —{" "}
          <strong>
            <a href="/auth">Login Here</a>
          </strong>
        </Alert>
      </Modal>
    </div>
  );
};

export default SearchList;
