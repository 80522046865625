import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Link, Tooltip, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CompareToolBar from "./CompareToolBar";
import SearchModal from "../search/SearchModal";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { deleteCompare } from "../../actions/compare";
import { getCompares } from "../../actions/compares";
import classes from "./styles.module.css";
import { darken, lighten } from "@mui/material/styles";

const CompareList = ({ state }) => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));
  const compare = useSelector((state) => state.compare);
  const compares = useSelector((state) => state.compares);
  const [pid, setPid] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rows, setRows] = useState([]);

  const getCountyState = (params) => {
    return `${params.row.county_name || " "} ${params.row.state_code || ""}`;
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.8) : lighten(color, 0.8);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

  const columns = [
    {
      field: "subject",
      headerName: "Subject",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClickDelete = (e) => {
          dispatch(deleteCompare(compare?.id));
        };

        return (
          compare?.subject_id === params.row.id && (
            <Tooltip title="Delete Compares">
              <IconButton
                size="small"
                disableRipple={true}
                onClick={onClickDelete}
              >
                <RemoveOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "compare",
      headerName: "Compare",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 80,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClickRemove = (e) => {
          dispatch(deleteCompare(compare?.id, { pid: params.row.id }));
        };

        return (
          compare?.compare_ids?.includes(params.row.id) && (
            <Tooltip title="Remove Compare">
              <IconButton
                size="small"
                disableRipple={true}
                onClick={onClickRemove}
              >
                <RemoveOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 55,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          setPid(params.row.id);
          setOpenModal(true);
        };

        return (
          <Tooltip title="View Parcel Detail">
            <IconButton size="small" disableRipple={true} onClick={onClick}>
              <VisibilityOutlinedIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "more",
      headerName: "More",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      minWidth: 55,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="View in New Window">
            <Link
              href={`/parcel/${JSON.stringify({
                state: params.row.state_code,
                id: params.row.id,
              })}`}
              target="_blank"
            >
              <IconButton size="small" disableRipple={true}>
                <MoreHorizOutlinedIcon color="primary" />
              </IconButton>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "county_state",
      headerName: "County State",
      minWidth: 150,
      flex: 1,
      valueGetter: getCountyState,
    },
    { field: "parcel_id", headerName: "Parcel ID", minWidth: 200, flex: 1 },
    {
      field: "alt_parcel_id",
      headerName: "Alt Parcel ID",
      minWidth: 200,
      flex: 1,
    },
    { field: "address", headerName: "Address", minWidth: 200, flex: 1 },
    {
      field: "acres",
      headerName: "Land Acres",
      type: "number",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "imp_sqft",
      headerName: "Building Sqft",
      type: "number",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "property_use",
      headerName: "Property Use",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "tax_district",
      headerName: "Tax District",
      minWidth: 150,
      flex: 1,
    },
    { field: "subdivision", headerName: "Subdivision", minWidth: 300, flex: 1 },
    {
      field: "neighborhood",
      headerName: "Neighborhood",
      minWidth: 200,
      flex: 1,
    },
    { field: "trs", headerName: "Twp-Rng-Sec", minWidth: 120, flex: 1 },
    { field: "owner_name", headerName: "Owner Name", minWidth: 300, flex: 1 },
    {
      field: "owner_address",
      headerName: "Owner Address",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "sale_date",
      headerName: "Last Sale Date",
      type: "date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "sale_price",
      headerName: "Last Sale Price",
      type: "number",
      minWidth: 150,
      flex: 1,
    },
  ];

  useEffect(() => {
    setRows([]);

    compares?.length &&
      compares?.map((parcel, i) => {
        setRows((rows) => [
          ...rows,
          {
            id: parcel.id,
            state_code: parcel.state_code,
            county_name: parcel.county_name,
            parcel_id: parcel.parcel_id,
            alt_parcel_id: parcel.alt_parcel_id,
            address: parcel.address,
            acres: parcel.acres ? Number(parcel.acres) : null,
            imp_sqft: parcel.imp_sqft ? Number(parcel.imp_sqft) : null,
            property_use: parcel.property_use,
            tax_district: parcel.school_district_name,
            subdivision: parcel.subdivision_name,
            neighborhood: parcel.neighborhood_name,
            trs: parcel.trs,
            owner_name: parcel.owner_name,
            owner_address: parcel.owner_address,
            sale_date: parcel.sale_date
              ? parcel.sale_date.substring(0, 10)
              : null,
            sale_price: parcel.sale_price ? Number(parcel.sale_price) : null,
            lat: parcel.lat,
            lng: parcel.lng,
            seq: parcel.seq,
          },
        ]);
      });
  }, [compares]);

  useEffect(() => {
    if (user?.result?.id && state) {
      dispatch(getCompares(user.result.id, state));
    }
  }, [compare]);

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <Box
          sx={{
            "& .subject": {
              bgcolor: (theme) =>
                getBackgroundColor(theme.palette.info.main, theme.palette.mode),
              "&:hover": {
                bgcolor: (theme) =>
                  getHoverBackgroundColor(
                    theme.palette.info.main,
                    theme.palette.mode
                  ),
              },
            },
          }}
        >
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            rowHeight={50}
            pageSize={6}
            rowsPerPageOptions={[6]}
            pagination
            initialState={{
              columns: {
                columnVisibilityModel: {
                  compare: user?.result ? true : false,
                  alt_parcel_id: false,
                  acres: false,
                  imp_sqft: false,
                  property_use: false,
                  tax_district: false,
                  subdivision: false,
                  neighborhood: false,
                  trs: false,
                  owner_address: false,
                },
              },
            }}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            density="standard"
            components={{
              Toolbar: CompareToolBar,
            }}
            componentsProps={{ toolbar: { state: state } }}
            getRowClassName={(params) => params.row.seq === 0 && "subject"}
          />
        </Box>
      </div>
      <SearchModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        state={state}
        pid={pid}
      />
    </div>
  );
};

export default CompareList;
