import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  Button,
  Divider,
} from "@mui/material";
import Cookies from "js-cookie";
import PayPal from "./PayPal";

const Donation = ({ setDonation }) => {
  const [amount, setAmount] = useState("");
  const [other, setOther] = useState("");
  const [payment, setPayment] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleAmount = (e) => {
    setAmount(e.target.value);
    setOther("");
    setPayment(e.target.value);
  };

  const handleOther = (e) => {
    setOther(e.target.value);
    setAmount("");
    setPayment(e.target.value);
  };

  const handleClose = () => {
    setDonation("no");
    Cookies.set("donation", "no", {
      expires: 1,
    });
  };

  const handleDonate = () => {
    setCheckout(true);
  };

  const handleComplete = () => {
    setDonation("no");
  };

  return (
    <Box noValidate sx={{ width: "100%", p: 1 }}>
      {completed ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl sx={{ width: 400 }}>
            <Typography sx={{ fontSize: 14 }} color="success.main" gutterBottom>
              Thank you for your support! We will keep improving!{" "}
              <Link component="button" variant="body2" onClick={handleComplete}>
                CLOSE
              </Link>
            </Typography>
          </FormControl>
        </Grid>
      ) : checkout && payment ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl sx={{ mt: 1, width: 400 }}>
            <PayPal
              payment={payment}
              setCheckout={setCheckout}
              setCompleted={setCompleted}
            />
          </FormControl>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={2}>
          <Grid item md={12} xs={12}>
            <FormHelperText sx={{ ml: 1, pr: 1, fontSize: 13 }}>
              AssessorInfo.org is a free website that depends on donations to
              stay active and improving. If everyone can give just a little, we
              can keep improving for years to come.
            </FormHelperText>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl sx={{ ml: 1, width: "100%" }}>
              <RadioGroup
                row
                name="donation"
                value={amount}
                onChange={handleAmount}
              >
                <FormControlLabel
                  value="10"
                  control={<Radio size="small" />}
                  label="$10"
                  sx={{ width: "23%" }}
                />
                <FormControlLabel
                  value="25"
                  control={<Radio size="small" />}
                  label="$25"
                  sx={{ width: "23%" }}
                />
                <FormControlLabel
                  value="50"
                  control={<Radio size="small" />}
                  label="$50"
                  sx={{ width: "23%" }}
                />
                <FormControlLabel
                  value="100"
                  control={<Radio size="small" />}
                  label="$100"
                  sx={{ width: "23%" }}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item md={2.4} xs={4}>
            <TextField
              name="other"
              label="Other"
              size="small"
              variant="outlined"
              type="number"
              inputProps={{ min: 1 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={other}
              sx={{ mr: 1, ml: 1, width: "100%" }}
              onChange={handleOther}
            />
          </Grid>
          <Grid item md={1.6} xs={4}>
            <Button
              variant="contained"
              size="small"
              sx={{ mt: 0.5, ml: 1 }}
              onClick={handleDonate}
              disabled={!payment}
            >
              Donate
            </Button>
          </Grid>
          <Grid item md={2} xs={4}>
            <Button
              variant="outlined"
              color="warning"
              size="small"
              sx={{ mt: 0.5 }}
              onClick={handleClose}
            >
              Hide
            </Button>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ mt: 1.5 }} />
    </Box>
  );
};

export default Donation;
