import React, { useState } from "react";
import { Grid } from "@mui/material";
import ImageModal from "./ImageModal";
import classes from "./styles.module.css";

const ParcelImage = ({ parcel }) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);

  const handleOpen = (e) => {
    setImage(e.target.src);
    setOpen(true);
  };

  return (
    <div className={classes.imageList}>
      {parcel?.photos ? (
        <Grid container spacing={1}>
          {parcel?.photos.map((url, i) => (
            <Grid key={i} item md={2.4} xs={6} className={classes.imageItem}>
              <img
                src={`${url}`}
                loading="lazy"
                onClick={handleOpen}
                width="100%"
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
      <ImageModal setOpen={setOpen} open={open} image={image} />
    </div>
  );
};

export default ParcelImage;
