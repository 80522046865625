import { combineReducers } from "redux";

import auth from "./auth";
import parcel from "./parcel";
import parcels from "./parcels";
import compare from "./compare";
import compares from "./compares";
import compareDetail from "./compareDetail";

export const reducers = combineReducers({ auth, parcel, parcels, compare, compares, compareDetail });
