export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

export const FETCH_PARCEL = "FETCH_PARCEL";
export const FETCH_PARCELS = "FETCH_PARCELS";

export const FETCH_COMPARE = "FETCH_COMPARE";
export const FETCH_COMPARES = "FETCH_COMPARES";
export const CREATE_COMPARE = "CREATE_COMPARE";
export const UPDATE_COMPARE = "UPDATE_COMPARE";
export const DELETE_COMPARE = "DELETE_COMPARE";
export const VIEW_COMPARES = "VIEW_COMPARES";
