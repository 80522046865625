import { AUTH } from "../constants/actionTypes";
import * as api from "../api/auth.js";
import Cookies from "js-cookie";

export const signin = (form, token, navigate, setError) => async (dispatch) => {
  try {
    setError(null);
    form.token = token;
    const { data } = await api.signIn(form);

    dispatch({ type: AUTH, data });

    if (data.result.last_search) {
      navigate(
        `/search/${JSON.stringify(data.result.last_search)}`
      );
    } else {
      navigate("/");
    }
  } catch (error) {
    setError(error.response.data.message);
  }
};

export const signup = (form, token, navigate, setError) => async (dispatch) => {
  try {
    setError(null);
    form.token = token;
    const { data } = await api.signUp(form);

    dispatch({ type: AUTH, data });

    navigate("/");
  } catch (error) {
    setError(error.response.data.message);
  }
};

export const forget =
  (form, token, setSuccess, setError) => async (dispatch) => {
    try {
      setError(null);
      form.token = token;
      const { data } = await api.forget(form);
      setSuccess(data.message);
    } catch (error) {
      setError(error.response.data.message);
    }
  };
