import axios from "axios";

const api = axios.create({ baseURL: "/api/compare" });

api.interceptors.request.use((req) => {
  if (localStorage.getItem('assessorinfoprofile')) {
    req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('assessorinfoprofile')).token}`;
  }

  return req;
});

export const fetchCompare = (getParams) => api.get('', {params: getParams});
export const createCompare = (post) => api.post('/create', post);
export const updateCompare = (id, post) => api.post(`/update/${id}`, post);
export const deleteCompare = (id, post) => api.post(`/delete/${id}`, post);