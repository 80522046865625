import React from "react";
import { Grid, Alert, AlertTitle } from "@mui/material";
import HomeHeader from "../home/HomeHeader";

const ParcelError = () => {
  return (
    <div>
      <HomeHeader />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Alert severity="info" sx={{ width: "50vw", mt: "20px" }}>
            <AlertTitle>Parcel Not Found</AlertTitle>
            Wrong page, there is no parcel found. —{" "}
            <strong>
              <a href="/">GO HOME</a>
            </strong>
          </Alert>
        </Grid>
      </Grid>
    </div>
  );
};

export default ParcelError;
