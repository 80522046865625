import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import PrintIcon from "../other/PrintIcon";
import ParcelDetail from "./ParcelDetail";
import ParcelImage from "./ParcelImage";
import ParcelMap from "./ParcelMap";

const ParcelInfo = ({ print }) => {
  const parcel = useSelector((state) => state.parcel);

  return (
    <>
      {parcel ? (
        <Grid container spacing={1}>
          {print && (
            <Grid item xs={12}>
              <PrintIcon />
            </Grid>
          )}
          <Grid item xs={12} style={{ paddingTop: "0px" }}>
            <ParcelImage parcel={parcel} />
          </Grid>
          <Grid item xs={12}>
            <ParcelDetail parcel={parcel} />
          </Grid>
          <Grid item xs={12}>
            <ParcelMap parcel={parcel} />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default ParcelInfo;
