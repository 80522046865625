import { FETCH_PARCEL } from "../constants/actionTypes";
import * as api from "../api/parcel";

export const getParcel = (getParams, navigate) => async (dispatch) => {
  try {
    const { data } = await api.fetchParcel(getParams);
    if (data?.parcel) {
      dispatch({ type: FETCH_PARCEL, parcel: data.parcel });
    } else {
      navigate("/parcel/error")
    }
  } catch (error) {
    console.log(error.message);
  }
};
