import { VIEW_COMPARES } from "../constants/actionTypes";

export default (compareDetail = null, action) => {
  switch (action.type) {
    case VIEW_COMPARES:
      return action.compareDetail;
    default:
      return compareDetail;
  }
};
