import React, { useRef, useEffect } from "react";
import { Paper, Typography, Link } from "@mui/material";
import Cookies from "js-cookie";
import { createDonation } from "../../api/donation";

const PayPal = ({ payment, setCheckout, setCompleted }) => {
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));

  const paypal = useRef();

  const handleCancel = () => {
    setCheckout(false);
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        style: {
          color: "blue",
        },
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "AssessorInfo Donation",
                amount: {
                  currency_code: "USD",
                  value: payment,
                },
              },
            ],
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
            payer: {
              name: {
                given_name: user?.result?.first_name,
                surname: user?.result?.last_name,
              },
              email_address: user?.result?.email,
            },
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (order?.status === "COMPLETED") {
            Cookies.set("donation", "no", {
              expires: 30,
            });
            setCompleted(true);

            createDonation({
              transaction_id: order.purchase_units[0].payments.captures[0].id,
              donation_amount: payment,
              first_name: order.payer.name.given_name,
              last_name: order.payer.name.surname,
              email: order.payer.email_address,
            });
          }
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <Paper elevation={0}>
      <Typography sx={{ fontSize: 14 }} color="info.main" gutterBottom>
        Please select a payment method to donate the $
        {payment.toLocaleString("en-US")}.<br />
        Or click{" "}
        <Link component="button" variant="body2" onClick={handleCancel}>
          HERE
        </Link>{" "}
        to cancel the payment and go back.
      </Typography>
      <div ref={paypal}></div>
    </Paper>
  );
};

export default PayPal;
