import { FETCH_PARCELS } from "../constants/actionTypes";

export default (parcels = null, action) => {
  switch (action.type) {
    case FETCH_PARCELS:
      return action.parcels;
    default:
      return parcels;
  }
};
