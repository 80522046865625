import React from "react";
import { CssBaseline } from "@mui/material";
import HomeHeader from "../components/home/HomeHeader";
import Form from "../components/profile/Form"
import CookieBanner from "../components/other/CookieBanner";

const Profile = () => {
  return (
    <div>
      <CssBaseline />
      <HomeHeader />
      <Form />
      <CookieBanner />
    </div>
  );
};

export default Profile;
