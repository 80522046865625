import {
  FETCH_COMPARE,
  CREATE_COMPARE,
  UPDATE_COMPARE,
  DELETE_COMPARE,
} from "../constants/actionTypes";
import * as api from "../api/compare";

export const getCompare = (user_id, state_code) => async (dispatch) => {
  try {
    const { data } = await api.fetchCompare({ user_id, state_code });

    dispatch({ type: FETCH_COMPARE, compare: data.compare });
  } catch (error) {
    console.log(error.message);
  }
};

export const createCompare = (post) => async (dispatch) => {
  try {
    const { data } = await api.createCompare(post);

    dispatch({ type: CREATE_COMPARE, compare: data.compare });
  } catch (error) {
    console.log(error);
  }
};

export const updateCompare = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updateCompare(id, post);

    dispatch({ type: UPDATE_COMPARE, compare: data.compare });
  } catch (error) {
    console.log(error.message);
  }
};

export const deleteCompare = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.deleteCompare(id, post);

    dispatch({ type: DELETE_COMPARE, compare: data.compare });
  } catch (error) {
    console.log(error.message);
  }
};
