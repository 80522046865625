import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import classes from "./styles.module.css";

const ParcelDetail = ({ parcel }) => {
  const [expanded, setExpanded] = useState({
    addr: true,
    owner: true,
    value: true,
    sale: true,
    land: true,
    resi: true,
    comm: true,
    moho: true,
    misc: true,
  });

  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === "addr") setExpanded({ ...expanded, addr: isExpanded });
    if (panel === "owner") setExpanded({ ...expanded, owner: isExpanded });
    if (panel === "value") setExpanded({ ...expanded, value: isExpanded });
    if (panel === "sale") setExpanded({ ...expanded, sale: isExpanded });
    if (panel === "land") setExpanded({ ...expanded, land: isExpanded });
    if (panel === "resi") setExpanded({ ...expanded, resi: isExpanded });
    if (panel === "comm") setExpanded({ ...expanded, comm: isExpanded });
    if (panel === "moho") setExpanded({ ...expanded, moho: isExpanded });
    if (panel === "misc") setExpanded({ ...expanded, misc: isExpanded });
  };

  return (
    <div>
      {parcel?.parcel ? (
        <Accordion
          disableGutters
          expanded={expanded.addr}
          onChange={handleChange("addr")}
          id={classes.location}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              LOCATION
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={"span"}>
              <Grid container>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Parcel ID</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.parcel_id}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Address</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.street}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Alt Parcel No.</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.alt_parcel_id}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Property Use</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.property_use}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Tax District</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.school_district_name}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Neighborhood</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.neighborhood_name}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Subdivision</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.subdivision_name}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Twp-Rng-Sec</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.township +
                      " - " +
                      parcel?.parcel?.range +
                      " - " +
                      parcel?.parcel?.section}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>City, State</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.city + ", " + parcel?.parcel?.state_code}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>County</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.county_name}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Legal Description</Box>
                </Grid>
                <Grid item md={10} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.parcel?.legal_description}
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.owner ? (
        <Accordion
          disableGutters
          expanded={expanded.owner}
          onChange={handleChange("owner")}
          id={classes.owner}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              OWNER
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component={"span"}>
              <Grid container>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Owner Name</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.owner?.owner_name}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Owner Name (Cont.)</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.owner?.owner_name2}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Address</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.owner?.address1}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>Address (Cont)</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.owner?.address2}
                  </Box>
                </Grid>
                <Grid item md={2} xs={5}>
                  <Box className={classes.parcelLabel}>City, State Zip</Box>
                </Grid>
                <Grid item md={4} xs={7}>
                  <Box className={classes.parcelContent}>
                    {parcel?.owner?.city_state_zip}
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.values ? (
        <Accordion
          disableGutters
          expanded={expanded.value}
          onChange={handleChange("value")}
          id={classes.value}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              VALUE HISTORY
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "text.secondary" }}>
                      Tax Year
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Imp FMV
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Land FMV
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      FMV
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Imp CAP
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Land CAP
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      CAP
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Imp ASD
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Land ASD
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      ASD
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Tax
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parcel?.values?.map((value, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        {value.tax_year}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${(value.fmv - value.land_fmv).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.land_fmv.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.fmv.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${(value.cap - value.land_cap).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.land_cap.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.cap.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${(value.asd - value.land_asd).toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.land_asd.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.asd.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${value.tax.toLocaleString("en-US")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.sales ? (
        <Accordion
          disableGutters
          expanded={expanded.sale}
          onChange={handleChange("sale")}
          id={classes.sale}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              SALES HISTORY
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "text.secondary" }} align="left">
                      Date
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Price
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Grantor
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Grantee
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Book
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Page
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Stamps
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Validity
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Instrument
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }} align="right">
                      Vac/Imp
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parcel?.sales?.map((sale, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {sale.sale_date}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${sale.sale_price.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.grantor}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.grantee}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.deed_book}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.deed_page}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        ${sale.revenue_stamps.toLocaleString("en-US")}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.validity_code}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.instrument}
                      </TableCell>
                      <TableCell
                        className={classes.parcelContentTable}
                        align="right"
                      >
                        {sale.vac_improved}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.lands ? (
        <Accordion
          disableGutters
          expanded={expanded.land}
          onChange={handleChange("land")}
          id={classes.land}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              LAND
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcel?.lands?.map((land, i) => (
              <TableContainer key={i}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "text.secondary" }}>
                        Land No.{i + 1}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        <Typography component={"span"}>
                          <Grid container>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Land Unit
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.land_unit}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Acres</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.acres
                                  ? land.acres.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Length</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.length
                                  ? land.length.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Ag Acres
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.ag_acres
                                  ? land.ag_acres.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Zoning</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.zoning}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Sqft</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.sqft
                                  ? land.sqft.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Width</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.width
                                  ? land.width.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Ag Use</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.ag_use}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Lots</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.lots
                                  ? land.lots.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Frontage
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.frontage
                                  ? land.frontage.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Depth</Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.depth
                                  ? land.depth.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Soil Use
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {land.ag_prod}
                              </Box>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.resis ? (
        <Accordion
          disableGutters
          expanded={expanded.resi}
          onChange={handleChange("resi")}
          id={classes.resi}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              RESIDENTIAL BUILDINGS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcel?.resis?.map((resi, i) => (
              <TableContainer key={i}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "text.secondary" }}>
                        Building No.{i + 1}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        <Typography component={"span"}>
                          <Grid container>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Area</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.area
                                  ? resi.area.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.floor_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Heat</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.heat}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Design</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.design}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.floor_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>A/C</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.air}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Style</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.style}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Ext Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.ext_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace No.
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.fireplace_count
                                  ? resi.fireplace_count.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Occupancy
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.occupancy}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Int Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.int_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.fireplace}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Quality</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.quality}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Foundation
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.foundation}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Story</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.story}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Condition
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.condition}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Beds</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.beds
                                  ? resi.beds.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Wall Height
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.wall_height
                                  ? resi.wall_height.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.roof_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Baths</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.baths
                                  ? resi.baths.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Year Built
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.year_built}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.roof_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Rooms</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.rooms
                                  ? resi.rooms.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Effective Year
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.effective_year}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Additions
                              </Box>
                            </Grid>
                            <Grid item md={10.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {resi.additions}
                              </Box>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.comms ? (
        <Accordion
          disableGutters
          expanded={expanded.comm}
          onChange={handleChange("comm")}
          id={classes.comm}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              COMMERCIAL BUILDINGS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcel?.comms?.map((comm, i) => (
              <TableContainer key={i}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "text.secondary" }}>
                        Building No.{i + 1}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        <Typography component={"span"}>
                          <Grid container>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Area</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.area
                                  ? comm.area.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.floor_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Heat</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.heat}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Design</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.design}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.floor_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>A/C</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.air}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Style</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.style}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Ext Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.ext_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace No.
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.fireplace_count
                                  ? comm.fireplace_count.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Occupancy
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.occupancy}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Int Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.int_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.fireplace}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Quality</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.quality}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Foundation
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.foundation}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Story</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.story}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Condition
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.condition}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Beds</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.beds
                                  ? comm.beds.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Wall Height
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.wall_height
                                  ? comm.wall_height.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.roof_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Baths</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.baths
                                  ? comm.baths.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Year Built
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.year_built}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.roof_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Rooms</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.rooms
                                  ? comm.rooms.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Effective Year
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.effective_year}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Additions
                              </Box>
                            </Grid>
                            <Grid item md={10.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {comm.additions}
                              </Box>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.mohos ? (
        <Accordion
          disableGutters
          expanded={expanded.moho}
          onChange={handleChange("moho")}
          id={classes.moho}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              MOBILE HOMES
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcel?.mohos?.map((moho, i) => (
              <TableContainer key={i}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "text.secondary" }}>
                        Building No.{i + 1}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        <Typography component={"span"}>
                          <Grid container>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Area</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.area
                                  ? moho.area.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Ext Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.ext_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Story</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.story}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Design</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.design}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Int Wall
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.int_wall}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Wall Height
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.wall_height
                                  ? moho.wall_height.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Style</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.style}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Foundation
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.foundation}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Year Built
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.year_built}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Occupancy
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.occupancy}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Beds</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.beds
                                  ? moho.beds.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Effective Year
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.effective_year}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Quality</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.quality}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Baths</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.baths
                                  ? moho.baths.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>MH Make</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.moho_make}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Condition
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.condition}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Rooms</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.rooms
                                  ? moho.rooms.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                MH Model
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.moho_model}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.roof_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Heat</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.heat}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                MH Serial
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.moho_serial_no}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Roof Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.roof_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>A/C</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.air}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>MH VIN</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.moho_vin}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Type
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.floor_type}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace No.
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.fireplace_count
                                  ? moho.fireplace_count.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}></Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}></Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Floor Cover
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.floor_cover}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Fireplace
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.fireplace}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}></Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}></Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Additions
                              </Box>
                            </Grid>
                            <Grid item md={10.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {moho.additions}
                              </Box>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {parcel?.miscs ? (
        <Accordion
          disableGutters
          expanded={expanded.misc}
          onChange={handleChange("misc")}
          id={classes.misc}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              component={"span"}
              className={classes.parcelTitle}
              style={{ fontWeight: "bold" }}
            >
              MISCELLANEOUS
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {parcel?.miscs?.map((misc, i) => (
              <TableContainer key={i}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: "text.secondary" }}>
                        Miscellaneous No.{i + 1}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className={classes.parcelContentTable}
                        component="th"
                        scope="row"
                      >
                        <Typography component={"span"}>
                          <Grid container>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Description
                              </Box>
                            </Grid>
                            <Grid item md={10.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.code}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Area</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.area
                                  ? misc.area.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Length</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.length
                                  ? misc.length.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Quality</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.quality}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Year Built
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.year_built}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>Width</Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.width
                                  ? misc.width.toLocaleString("en-US")
                                  : ""}
                              </Box>
                            </Grid>
                            <Grid item md={1.5} xs={5}>
                              <Box className={classes.parcelLabel}>
                                Condition
                              </Box>
                            </Grid>
                            <Grid item md={2.5} xs={7}>
                              <Box className={classes.parcelContent}>
                                {misc.condition}
                              </Box>
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ParcelDetail;
