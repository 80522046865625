import React from "react";
import { Modal, Box, Fade, Backdrop } from "@mui/material";

const ImageModal = ({ setOpen, open, image }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
        >
          <img src={`${image}`} loading="lazy" />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageModal;
