import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getOptions } from "../../api/options";

const SearchForm = ({
  setSearch,
  setSearchFields,
  setTab,
  search,
  searchFields,
}) => {
  const [reLoad, setReLoad] = useState(true);
  const [counties, setCounties] = useState([{ code: "", name: "Any" }]);
  const [propertyUses, setPropertyUses] = useState([{ code: "", name: "Any" }]);
  const [taxDists, setTaxDists] = useState([{ code: "", name: "Any" }]);
  const [subds, setSubds] = useState([{ code: "", name: "Any" }]);
  const [nbhds, setNbhds] = useState([{ code: "", name: "Any" }]);
  const [townships, setTownships] = useState([{ code: "", name: "Any" }]);
  const [ranges, setRanges] = useState([{ code: "", name: "Any" }]);
  const sections = [
    { code: "", name: "Any" },
    { code: "1", name: "1" },
    { code: "2", name: "2" },
    { code: "3", name: "3" },
    { code: "4", name: "4" },
    { code: "5", name: "5" },
    { code: "6", name: "6" },
    { code: "7", name: "7" },
    { code: "8", name: "8" },
    { code: "9", name: "9" },
    { code: "10", name: "10" },
    { code: "11", name: "11" },
    { code: "12", name: "12" },
    { code: "13", name: "13" },
    { code: "14", name: "14" },
    { code: "15", name: "15" },
    { code: "16", name: "16" },
    { code: "17", name: "17" },
    { code: "18", name: "18" },
    { code: "19", name: "19" },
    { code: "20", name: "20" },
    { code: "21", name: "21" },
    { code: "22", name: "22" },
    { code: "23", name: "23" },
    { code: "24", name: "24" },
    { code: "25", name: "25" },
    { code: "26", name: "26" },
    { code: "27", name: "27" },
    { code: "28", name: "28" },
    { code: "29", name: "29" },
    { code: "30", name: "30" },
    { code: "31", name: "31" },
    { code: "32", name: "32" },
    { code: "33", name: "33" },
    { code: "34", name: "34" },
    { code: "35", name: "35" },
    { code: "36", name: "36" },
  ];
  const impTypes = [
    { code: "", name: "Any" },
    { code: "resi", name: "Residential" },
    { code: "comm", name: "Commercial" },
    { code: "moho", name: "Mobile Home" },
    { code: "vac", name: "Vacant Land" },
  ];
  const landUnits = [
    { code: "", name: "Any" },
    { code: "acre", name: "Acres" },
    { code: "agAcre", name: "Agriculture Acres" },
    { code: "sqft", name: "Square Feet" },
  ];

  const [addressDialog, setAddressDialog] = useState(false);
  const [ownerDialog, setOwnerDialog] = useState(false);
  const [saleDialog, setSaleDialog] = useState(false);
  const [landDialog, setLandDialog] = useState(false);
  const [impDialog, setImpDialog] = useState(false);

  useEffect(() => {
    getOptions("county", { state_code: search.state }).then((data) => {
      setCounties((counties) => [...counties, ...data]);
    });
  }, []);

  useEffect(() => {
    if (searchFields.county) {
      if (reLoad) {
        setReLoad(false);
      } else {
        setSearchFields({
          ...searchFields,
          parcelId: "",
          address: "",
          propertyUse: "",
          taxDist: "",
          subd: "",
          nbhd: "",
          township: "",
          range: "",
          section: "",
        });
      }

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "property_use",
      }).then((data) => {
        setPropertyUses([{ code: "", name: "Any" }]);
        setPropertyUses((propertyUses) => [...propertyUses, ...data]);
      });

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "school_district",
      }).then((data) => {
        setTaxDists([{ code: "", name: "Any" }]);
        setTaxDists((taxDists) => [...taxDists, ...data]);
      });

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "subdivision",
      }).then((data) => {
        setSubds([{ code: "", name: "Any" }]);
        setSubds((subds) => [...subds, ...data]);
      });

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "neighborhood",
      }).then((data) => {
        setNbhds([{ code: "", name: "Any" }]);
        setNbhds((nbhds) => [...nbhds, ...data]);
      });

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "township",
      }).then((data) => {
        setTownships([{ code: "", name: "Any" }]);
        setTownships((townships) => [...townships, ...data]);
      });

      getOptions("location", {
        state_code: search.state,
        county_name: searchFields.county,
        field: "range",
      }).then((data) => {
        setRanges([{ code: "", name: "Any" }]);
        setRanges((ranges) => [...ranges, ...data]);
      });
    }
  }, [searchFields.county]);

  const handleChange = (e) => {
    setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e) => {
    if (e.target.name === "county") {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "landUnit") {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
        landFrom: "",
        landTo: "",
      });
    } else if (e.target.name === "impType") {
      setSearchFields({
        ...searchFields,
        [e.target.name]: e.target.value,
        areaFrom: "",
        areaTo: "",
        bedFrom: "",
        bedTo: "",
        bathFrom: "",
        bathTo: "",
        roomFrom: "",
        roomTo: "",
      });
    } else
      setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
  };

  const handleAddressDialog = () => {
    setAddressDialog(!addressDialog);
  };

  const handleAddressClear = () => {
    setSearchFields({
      ...searchFields,
      parcelId: "",
      address: "",
      stNo: "",
      stNoFrom: "",
      stNoTo: "",
      stDir: "",
      stName: "",
      stType: "",
      city: "",
      propertyUse: "",
      taxDist: "",
      subd: "",
      nbhd: "",
      township: "",
      range: "",
      section: "",
    });
  };

  const handleOwnerDialog = () => {
    setOwnerDialog(!ownerDialog);
  };

  const handleOwnerClear = () => {
    setSearchFields({
      ...searchFields,
      ownerName: "",
      ownerAddress: "",
      ownerCity: "",
      ownerState: "",
      ownerZip: "",
    });
  };

  const handleSaleDialog = () => {
    setSaleDialog(!saleDialog);
  };

  const handleSaleClear = () => {
    setSearchFields({
      ...searchFields,
      saleFrom: "",
      saleTo: "",
      priceFrom: "",
      priceTo: "",
      grantor: "",
      grantee: "",
    });
  };

  const handleLandDialog = () => {
    setLandDialog(!landDialog);
  };

  const handleLandClear = () => {
    setSearchFields({
      ...searchFields,
      landUnit: "",
      landFrom: "",
      landTo: "",
    });
  };

  const handleImpDialog = () => {
    setImpDialog(!impDialog);
  };

  const handleImpClear = () => {
    setSearchFields({
      ...searchFields,
      impType: "",
      areaFrom: "",
      areaTo: "",
      bedFrom: "",
      bedTo: "",
      bathFrom: "",
      bathTo: "",
      roomFrom: "",
      roomTo: "",
    });
  };

  const handleKeyUp = (e, filter) => {
    if (e.key === "Enter") {
      if (filter === "address") {
        setAddressDialog(false);
      } else if (filter === "owner") {
        setOwnerDialog(false);
      } else if (filter === "sales") {
        setSaleDialog(false);
      } else if (filter === "land") {
        setLandDialog(false);
      } else if (filter === "imp") {
        setImpDialog(false);
      } else if (!filter) {
        handleSubmit();
      }
    }
  };

  const handleSubmit = () => {
    setSearch({ ...searchFields, address: "", bounds: "" });
    setSearchFields({ ...searchFields, address: "" });
    setTab(0);
  };

  return (
    <Box noValidate sx={{ width: "100%", p: 1 }}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={2} xs={6}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="counties-label" size="small">
              County...
            </InputLabel>
            <Select
              name="county"
              labelId="counties-label"
              size="small"
              value={counties.length > 1 ? searchFields.county : ""}
              label="County..."
              options={counties}
              onChange={handleSelectChange}
              autoFocus
            >
              {counties?.map((county, i) => (
                <MenuItem key={i} value={county.code}>
                  {county.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            name="parcelId"
            label="Parcel ID"
            size="small"
            variant="outlined"
            value={searchFields.parcelId}
            sx={{ width: "100%" }}
            onChange={handleChange}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid item md={1.2} xs={4}>
          <Button
            size="small"
            disableRipple={true}
            variant={
              searchFields.stNo ||
              searchFields.stNoFrom ||
              searchFields.stNoTo ||
              searchFields.stDir ||
              searchFields.stName ||
              searchFields.stType ||
              searchFields.city ||
              searchFields.propertyUse ||
              searchFields.taxDist ||
              searchFields.subd ||
              searchFields.nbhd ||
              searchFields.township ||
              searchFields.range ||
              searchFields.section
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleAddressDialog}
          >
            Address
          </Button>
        </Grid>
        <Grid item md={1.2} xs={4}>
          <Button
            size="small"
            disableRipple={true}
            variant={
              searchFields.ownerName ||
              searchFields.ownerAddress ||
              searchFields.ownerCity ||
              searchFields.ownerState ||
              searchFields.ownerZip
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleOwnerDialog}
          >
            Owner
          </Button>
        </Grid>
        <Grid item md={1.2} xs={4}>
          <Button
            size="small"
            disableRipple={true}
            variant={
              searchFields.saleFrom ||
              searchFields.saleTo ||
              searchFields.priceFrom ||
              searchFields.priceTo ||
              searchFields.grantor ||
              searchFields.grantee
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleSaleDialog}
          >
            Sales
          </Button>
        </Grid>
        <Grid item md={1.2} xs={4}>
          <Button
            size="small"
            disableRipple={true}
            variant={
              searchFields.landUnit ||
              searchFields.landFrom ||
              searchFields.landTo
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleLandDialog}
          >
            Land
          </Button>
        </Grid>
        <Grid item md={1.2} xs={4}>
          <Button
            size="small"
            disableRipple={true}
            variant={
              searchFields.impType ||
              searchFields.areaFrom ||
              searchFields.areaTo ||
              searchFields.bedFrom ||
              searchFields.bedTo ||
              searchFields.bathFrom ||
              searchFields.bathTo ||
              searchFields.roomFrom ||
              searchFields.roomTo
                ? "contained"
                : "outlined"
            }
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleImpDialog}
          >
            Building
          </Button>
        </Grid>
        <Grid item md={2} xs={4}>
          <Button
            size="small"
            variant="contained"
            sx={{ mt: 0.5, width: "100%" }}
            onClick={handleSubmit}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={addressDialog}
        onClose={handleAddressDialog}
      >
        <DialogTitle>Address Filters</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} xs={6}>
              <TextField
                name="stNo"
                label="St No."
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 1 }}
                value={searchFields.stNo}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="stNoFrom"
                label="St No. From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 1 }}
                value={searchFields.stNoFrom}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="stNoTo"
                label="St No. To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 1 }}
                value={searchFields.stNoTo}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <TextField
                name="stDir"
                label="St Dir."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 2 }}
                value={searchFields.stDir}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="stName"
                label="St Name"
                size="small"
                variant="outlined"
                value={searchFields.stName}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={2} xs={6}>
              <TextField
                name="stType"
                label="St Type"
                size="small"
                variant="outlined"
                value={searchFields.stType}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="city"
                label="City"
                size="small"
                variant="outlined"
                value={searchFields.city}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "address")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="propertyUse-label" size="small">
                  Property Use...
                </InputLabel>
                <Select
                  name="propertyUse"
                  labelId="propertyUse-label"
                  size="small"
                  value={
                    propertyUses.length > 1 ? searchFields.propertyUse : ""
                  }
                  label="Property Use..."
                  options={propertyUses}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {propertyUses?.map((propertyUse, i) => (
                    <MenuItem key={i} value={propertyUse.code}>
                      {propertyUse.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="taxDist-label" size="small">
                  Tax District...
                </InputLabel>
                <Select
                  name="taxDist"
                  labelId="taxDist-label"
                  size="small"
                  value={taxDists.length > 1 ? searchFields.taxDist : ""}
                  label="Tax District..."
                  options={taxDists}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {taxDists?.map((taxDist, i) => (
                    <MenuItem key={i} value={taxDist.code}>
                      {taxDist.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="subd-label" size="small">
                  Subdivision...
                </InputLabel>
                <Select
                  name="subd"
                  labelId="subd-label"
                  size="small"
                  value={subds.length > 1 ? searchFields.subd : ""}
                  label="Subdivision..."
                  options={subds}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {subds?.map((subd, i) => (
                    <MenuItem key={i} value={subd.code}>
                      {subd.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="nbhd-label" size="small">
                  Neighborhood...
                </InputLabel>
                <Select
                  name="nbhd"
                  labelId="nbhd-label"
                  size="small"
                  value={nbhds.length > 1 ? searchFields.nbhd : ""}
                  label="Neighborhood..."
                  options={nbhds}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {nbhds?.map((nbhd, i) => (
                    <MenuItem key={i} value={nbhd.code}>
                      {nbhd.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="twp-label" size="small">
                  Twp...
                </InputLabel>
                <Select
                  name="township"
                  labelId="twp-label"
                  size="small"
                  value={townships.length > 1 ? searchFields.township : ""}
                  label="Twp..."
                  options={townships}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {townships?.map((township, i) => (
                    <MenuItem key={i} value={township.code}>
                      {township.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="range-label" size="small">
                  Rng...
                </InputLabel>
                <Select
                  name="range"
                  labelId="range-label"
                  size="small"
                  value={ranges.length > 1 ? searchFields.range : ""}
                  label="Rng..."
                  options={ranges}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {ranges?.map((range, i) => (
                    <MenuItem key={i} value={range.code}>
                      {range.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="section-label" size="small">
                  Sec...
                </InputLabel>
                <Select
                  name="section"
                  labelId="section-label"
                  size="small"
                  value={sections.length ? searchFields.section : ""}
                  label="Sec..."
                  options={sections}
                  disabled={!searchFields.county}
                  onChange={handleSelectChange}
                >
                  {sections?.map((section, i) => (
                    <MenuItem key={i} value={section.code}>
                      {section.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddressDialog}>Apply</Button>
          <Button onClick={handleAddressClear}>Clear</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={ownerDialog}
        onClose={handleOwnerDialog}
      >
        <DialogTitle>Owner Filters</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} xs={6}>
              <TextField
                name="ownerName"
                label="Owner Name"
                size="small"
                variant="outlined"
                value={searchFields.ownerName}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "owner")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="ownerAddress"
                label="Owner Address"
                size="small"
                variant="outlined"
                value={searchFields.ownerAddress}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "owner")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="ownerCity"
                label="Owner City"
                size="small"
                variant="outlined"
                value={searchFields.ownerCity}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "owner")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="ownerState"
                label="Owner State"
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 2 }}
                value={searchFields.ownerState}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "owner")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="ownerZip"
                label="Owner Zip"
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 5 }}
                value={searchFields.ownerZip}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "owner")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOwnerDialog}>Apply</Button>
          <Button onClick={handleOwnerClear}>Clear</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={saleDialog}
        onClose={handleSaleDialog}
      >
        <DialogTitle>Sales Filters</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} xs={6}>
              <TextField
                name="saleFrom"
                label="Sale From"
                size="small"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={searchFields.saleFrom}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="saleTo"
                label="Sale To"
                size="small"
                variant="outlined"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={searchFields.saleTo}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="priceFrom"
                label="Price From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={searchFields.priceFrom}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="priceTo"
                label="Price To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                value={searchFields.priceTo}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="grantor"
                label="Grantor"
                size="small"
                variant="outlined"
                value={searchFields.grantor}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="grantee"
                label="Grantee"
                size="small"
                variant="outlined"
                value={searchFields.grantee}
                sx={{ mt: 2, width: "100%" }}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "sales")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaleDialog}>Apply</Button>
          <Button onClick={handleSaleClear}>Clear</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={landDialog}
        onClose={handleLandDialog}
      >
        <DialogTitle>Land Filters</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="landUnit-label" size="small">
                  Land Unit...
                </InputLabel>
                <Select
                  name="landUnit"
                  labelId="landUnit-label"
                  size="small"
                  value={landUnits.length ? searchFields.landUnit : ""}
                  label="Land Unit..."
                  options={landUnits}
                  onChange={handleSelectChange}
                >
                  {landUnits?.map((landUnit, i) => (
                    <MenuItem key={landUnit.code} value={landUnit.code}>
                      {landUnit.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="landFrom"
                label="Land Size From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.landFrom}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.landUnit}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "land")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="landTo"
                label="Land Size To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.landTo}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.landUnit}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "land")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLandDialog}>Apply</Button>
          <Button onClick={handleLandClear}>Clear</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        open={impDialog}
        onClose={handleImpDialog}
      >
        <DialogTitle>Building Filters</DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={2}>
            <Grid item md={4} xs={6}>
              <FormControl sx={{ mt: 2, width: "100%" }}>
                <InputLabel id="impType-label" size="small">
                  Building Type...
                </InputLabel>
                <Select
                  name="impType"
                  labelId="impType-label"
                  size="small"
                  value={impTypes.length ? searchFields.impType : ""}
                  label="Building Type..."
                  options={impTypes}
                  onChange={handleSelectChange}
                >
                  {impTypes?.map((impType, i) => (
                    <MenuItem key={impType.code} value={impType.code}>
                      {impType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="areaFrom"
                label="Sqft From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.areaFrom}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <TextField
                name="areaTo"
                label="Sqft To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.areaTo}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="bedFrom"
                label="Bed From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.bedFrom}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="bedTo"
                label="Bed To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.bedTo}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="bathFrom"
                label="Bath From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.bathFrom}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="bathTo"
                label="Bath To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.bathTo}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="roomFrom"
                label="Room From"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.roomFrom}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                name="roomTo"
                label="Room To"
                size="small"
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                value={searchFields.roomTo}
                sx={{ mt: 2, width: "100%" }}
                disabled={!searchFields.impType}
                onChange={handleChange}
                onKeyUp={(e) => handleKeyUp(e, "imp")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImpDialog}>Apply</Button>
          <Button onClick={handleImpClear}>Clear</Button>
        </DialogActions>
      </Dialog>
      <Divider sx={{ mt: 1.5 }} />
    </Box>
  );
};

export default SearchForm;
