import {
  FETCH_COMPARE,
  CREATE_COMPARE,
  UPDATE_COMPARE,
  DELETE_COMPARE,
} from "../constants/actionTypes";

export default (compare = null, action) => {
  switch (action.type) {
    case FETCH_COMPARE:
      return action.compare;
    case CREATE_COMPARE:
      return action.compare;
    case UPDATE_COMPARE:
      return action.compare;
    case DELETE_COMPARE:
      return action.compare;
    default:
      return compare;
  }
};
