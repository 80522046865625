import React from "react";
import { Paper, Typography } from "@mui/material";
import classes from "../search/styles.module.css";

const ComparePaper = (props) => {
  return (
    <div>
      <Paper className={props.className} elevation={3}>
        <Typography
          variant="button"
          className={classes.paperContent}
        >
          {props.parcel.parcel.parcel_id}
        </Typography>
        <Typography
          variant="button"
          className={classes.paperContent}
          color="textSecondary"
        >
          {props.parcel.parcel.street}
        </Typography>
        <Typography
          variant="button"
          className={classes.paperContent}
          color="textSecondary"
        >
          {props.parcel.owner.owner_name}
        </Typography>
      </Paper>
    </div>
  );
};

export default ComparePaper;
