import axios from "axios";

const api = axios.create({ baseURL: "/api/profile" });

api.interceptors.request.use((req) => {
  if (localStorage.getItem("assessorinfoprofile")) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem("assessorinfoprofile")).token
    }`;
  }

  return req;
});

export const updateProfile = async (post) => {
  try {
    const {
      data: { password },
    } = await api.post(`/update`, post);

    return password;
  } catch (err) {
    console.log(err);
  }
};
