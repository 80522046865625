import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CssBaseline, Grid, Alert, AlertTitle } from "@mui/material";
import HomeHeader from "../components/home/HomeHeader";
import SearchHome from "../components/search/SearchHome";
import CookieBanner from "../components/other/CookieBanner";

const Map = () => {
  const navigate = useNavigate();
  const { params } = useParams();
  const [searchParams, setSearchParams] = useState(null);

  useEffect(() => {
    try {
      setSearchParams(JSON.parse(params));
    } catch (err) {
      setTimeout(() => {
        navigate("/error");
      });
    }
  }, []);

  return (
    <div>
      <CssBaseline />
      <HomeHeader />
      {searchParams?.state ? (
        <SearchHome searchParams={searchParams} />
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Alert severity="warning" sx={{ width: "50vw", mt: "20px" }}>
              <AlertTitle>Warning</AlertTitle>
              Wrong page, there is nothing here. —{" "}
              <strong>
                <a href="/">GO HOME</a>
              </strong>
            </Alert>
          </Grid>
        </Grid>
      )}
      <CookieBanner />
    </div>
  );
};

export default Map;
