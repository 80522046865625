import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CssBaseline, Grid, Box, Alert, AlertTitle } from "@mui/material";
import HomeHeader from "../components/home/HomeHeader";
import CompareHome from "../components/compare/CompareHome";
import CookieBanner from "../components/other/CookieBanner";

const Compare = () => {
  const navigate = useNavigate();
  const { params } = useParams();
  const [compareParams, setCompareParams] = useState(null);
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));

  useEffect(() => {
    try {
      setCompareParams(JSON.parse(params));
    } catch (err) {
      setTimeout(() => {
        navigate("/error");
      });
    }
  }, []);

  return (
    <div>
      <CssBaseline />
      <HomeHeader />
      <Box
        sx={{
          width: "98vw",
          marginLeft: "1vw",
        }}
      >
        {user?.result && compareParams?.state ? (
          <CompareHome user_id={user.result.id} state={compareParams.state} />
        ) : user?.result ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Alert severity="warning" sx={{ width: "50vw", mt: "20px" }}>
                <AlertTitle>Warning</AlertTitle>
                Wrong page, there is nothing here. —{" "}
                <strong>
                  <a href="/">GO HOME</a>
                </strong>
              </Alert>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Alert severity="info" sx={{ width: "50vw", mt: "20px" }}>
                <AlertTitle>Warning</AlertTitle>
                Please Login before using compare —{" "}
                <strong>
                  <a href="/auth">Login Here</a>
                </strong>
              </Alert>
            </Grid>
          </Grid>
        )}
      </Box>
      <CookieBanner />
    </div>
  );
};

export default Compare;
