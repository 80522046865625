import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Container,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import ReCAPTCHA from "react-google-recaptcha";
import { sendMessage } from "../../api/contact";
import classes from "./styles.module.css";

const ContactForm = ({ initialSubject }) => {
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));

  const initialState = {
    name: "",
    business: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };

  const [form, setForm] = useState(initialState);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const reRef = useRef();

  const subjects = [
    { code: "acc", name: "Account Support" },
    { code: "data", name: "Request to Purchase Data" },
    { code: "part", name: "Participate to be a Data Provider" },
    { code: "other", name: "Others" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    sendMessage(form, token).then((data) => {
      if (data) setSuccess("Message sent.");
      else setError("Something was wrong.");
    });
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "phone" && value.length > 15)
      value = value.substring(0, 15);
    if (e.target.name === "message" && value.length > 200)
      value = value.substring(0, 200);
    setForm({ ...form, [e.target.name]: value });
  };

  useEffect(() => {
    if (user?.result)
      setForm({
        ...form,
        name: (
          (user.result.first_name ?? "") +
          " " +
          (user.result.last_name ?? "")
        ).trim(),
        business: user.result.business_name ?? "",
        email: user.result.email ?? "",
      });
    setForm({ ...form, subject: initialSubject ?? "acc" });
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={3}>
        {initialSubject === "data" && (
          <Grid container justifyContent="center" sx={{ mb: 5 }}>
            <Grid item xs={11}>
              <strong>
                You can purchase Property Report which contains your last search
                results. Price of the report is $0.05 per parcel. The report
                will have available parcel information including physical
                address, legal description, ownership, current sales, market
                value, building count and geojson. The report will be in csv
                format. After you make successful purchase, you will get an
                email containing the download link of the file. Please make sure
                the email address associated with your account is valid. You can
                also download reports in this page. Please make sure to download
                the file as soon as possible. The download link will expire in
                72 hours. You cannot use group membership to purchase property
                report.
              </strong>
            </Grid>
          </Grid>
        )}
        {initialSubject === "part" && (
          <Grid container justifyContent="center" sx={{ mb: 5 }}>
            <Grid item xs={11}>
              <strong>Participate</strong>
            </Grid>
          </Grid>
        )}
        <Avatar className={classes.avatar} sx={{ bgcolor: "#fff" }}>
          <MailOutlinedIcon color="primary" />
        </Avatar>
        <form className={classes.contactForm} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {success && (
              <>
                <Grid item xs={0} sm={3} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Alert
                    severity="success"
                    onClose={() => {
                      setSuccess(null);
                    }}
                  >
                    {success}
                  </Alert>
                </Grid>
                <Grid item xs={0} sm={3} md={4}></Grid>
              </>
            )}
            {error && (
              <>
                <Grid item xs={0} sm={3} md={4}></Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setError(null);
                    }}
                  >
                    {error}
                  </Alert>
                </Grid>
                <Grid item xs={0} sm={3} md={4}></Grid>
              </>
            )}
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="subject-label" size="small">
                  Subject *
                </InputLabel>
                <Select
                  name="subject"
                  labelId="subject-label"
                  size="small"
                  value={form.subject}
                  label="Building Type..."
                  options={subjects}
                  onChange={handleChange}
                  required
                  disabled={initialSubject ? true : false}
                >
                  {subjects?.map((subject, i) => (
                    <MenuItem key={subject.code} value={subject.code}>
                      {subject.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={form.email}
                size="small"
                name="email"
                label="Email Address"
                onChange={handleChange}
                type="email"
                fullWidth
                autoFocus
                required={true}
              />
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={form.name}
                size="small"
                name="name"
                label="Contact Name"
                onChange={handleChange}
                fullWidth
                autoFocus
                required={true}
              />
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={form.phone}
                size="small"
                name="phone"
                label="Phone Number"
                onChange={handleChange}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={form.business}
                size="small"
                name="business"
                label="Business Name"
                onChange={handleChange}
                fullWidth
                autoFocus
                required={true}
              />
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                value={form.message}
                size="small"
                name="message"
                label="Message (max 200 words)"
                multiline
                rows={6}
                fullWidth
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send Message
              </Button>
            </Grid>
            <Grid item xs={0} sm={3} md={4}></Grid>
          </Grid>
        </form>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size="invisible"
          ref={reRef}
        />
      </Paper>
    </Container>
  );
};

export default ContactForm;
