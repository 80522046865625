import axios from "axios";

const api = axios.create({ baseURL: "/api" });

export const getChart = async (state, parcels) => {
  let pids = [];
  parcels.map((parcel, i) => {
    pids.push(parcel.id);
  });
  const {
    data: { chart },
  } = await api.get("/chart", { params: { state: state, pids: pids.join() } });

  return chart;
};
