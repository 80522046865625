import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { darken, lighten } from "@mui/material/styles";

const CompareDetail = ({
  subject,
  compare1,
  compare2,
  compare3,
  compare4,
  compare5,
}) => {
  const additions = ["1", "2", "3", "4", "5"];

  const headerStyle = {
    color: "text.secondary",
    border: 1,
    borderColor: "rgba(224, 224, 224, 1)",
  };

  const bodyStyle = {
    border: 1,
    borderColor: "rgba(224, 224, 224, 1)",
  };

  const subjectStyle = {
    border: 1,
    borderColor: "white",
    bgcolor: (theme) =>
      getBackgroundColor(theme.palette.info.main, theme.palette.mode),
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.8) : lighten(color, 0.8);

  const calLandUnit = (parcel) => {
    parcel.land_size = "";
    let sqft = 0;
    let acres = 0;
    if (parcel?.lands?.length) {
      parcel.lands.map((land, i) => {
        sqft += Number(land.sqft);
        acres += Number(land.acres);
      });
      if (sqft > 0) {
        parcel.land_size = sqft.toLocaleString("en-US") + " Sqft";
      } else if (acres > 0) {
        parcel.land_size = acres.toLocaleString("en-US") + " Acres";
      } else if (parcel.parcel.acres > 0) {
        parcel.land_size = parcel.parcel.acres.toLocaleString("en-US") + " Acres";
      } else if (parcel.parcel.sqft > 0) {
        parcel.land_size = parcel.parcel.sqft.toLocaleString("en-US") + " Sqft";
      }
    }
    return parcel.land_size;
  };

  const getColor = (subValue, compValue) => {
    return compValue >= subValue ? "green" : "red";
  };

  const getLandColor = (subValue, compValue) => {
    let subLand = 0;
    let compLand = 0;
    if (subValue !== "" && compValue !== "") {
      if (subValue.includes("Sqft"))
        subLand = parseInt(subValue.replace(" Sqft", "").replace(/\D/g,''));
      else subLand = parseInt(subValue.replace(" Acres", "").replace(/\D/g,'')) * 43560;
      if (compValue.includes("Sqft"))
        compLand = parseInt(compValue.replace(" Sqft", "").replace(/\D/g,''));
      else compLand = parseInt(compValue.replace(" Acres", "").replace(/\D/g,'')) * 43560;
    }
    return compLand >= subLand ? "green" : "red";
  };

  const getValueDiff = (subValue, compValue) => {
    if (subValue > 0 && compValue > 0)
      return (
        " " +
        (compValue > subValue ? "+" : "") +
        (((compValue - subValue) / subValue) * 100).toFixed(2) +
        "%"
      );
    return "";
  };

  const getMonthDiff = (subValue, compValue) => {
    if (subValue !== undefined && compValue !== undefined) {
      let subDate = new Date(subValue);
      let compDate = new Date(compValue);
      let diff =
        compDate.getMonth() -
        subDate.getMonth() +
        12 * (compDate.getFullYear() - subDate.getFullYear());
      return " " + (diff >= 0 ? "+" : "") + diff + "MTH";
    }
    return "";
  };

  const getLandDiff = (subValue, compValue) => {
    if (subValue !== "" && compValue !== "") {
      let subLand = 0;
      let compLand = 0;
      if (subValue.includes("Sqft"))
        subLand = parseInt(subValue.replace(" Sqft", "").replace(/\D/g,''));
      else subLand = parseInt(subValue.replace(" Acres", "").replace(/\D/g,'')) * 43560;
      if (compValue.includes("Sqft"))
        compLand = parseInt(compValue.replace(" Sqft", "").replace(/\D/g,''));
      else compLand = parseInt(compValue.replace(" Acres", "").replace(/\D/g,'')) * 43560;
      if (subLand > 0 && compLand > 0)
        return (
          " " +
          (compLand > subLand ? "+" : "") +
          (((compLand - subLand) / subLand) * 100).toFixed(2) +
          "%"
        );
    }
    return "";
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            {subject && (
              <TableCell>
                {subject?.parcel?.photo && (
                  <img
                    src={`${subject.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
            {compare1 && (
              <TableCell>
                {compare1?.parcel?.photo && (
                  <img
                    src={`${compare1.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
            {compare2 && (
              <TableCell>
                {compare2?.parcel?.photo && (
                  <img
                    src={`${compare2.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
            {compare3 && (
              <TableCell>
                {compare3?.parcel?.photo && (
                  <img
                    src={`${compare3.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
            {compare4 && (
              <TableCell>
                {compare4?.parcel?.photo && (
                  <img
                    src={`${compare4.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
            {compare5 && (
              <TableCell>
                {compare5?.parcel?.photo && (
                  <img
                    src={`${compare5.parcel.photo}`}
                    width="100%"
                    max-height="190px"
                    loading="lazy"
                  />
                )}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Parcel ID
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.parcel_id}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.parcel_id}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.parcel_id}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.parcel_id}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.parcel_id}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.parcel_id}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Address
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.street}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.street}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.street}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.street}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.street}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.street}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              City
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.city}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.city}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.city}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.city}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.city}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.city}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              County
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.county_name}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.county_name}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.county_name}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.county_name}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.county_name}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.county_name}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              State
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.state_code}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.state_code}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.state_code}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.state_code}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.state_code}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.state_code}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Subdivision
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.subdivision_name}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.subdivision_name}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.subdivision_name}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.subdivision_name}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.subdivision_name}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.subdivision_name}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Neighborhood
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject.parcel?.neighborhood_name}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1.parcel?.neighborhood_name}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2.parcel?.neighborhood_name}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3.parcel?.neighborhood_name}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4.parcel?.neighborhood_name}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5.parcel?.neighborhood_name}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Appraisal Value
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                ${subject?.values?.[0].fmv.toLocaleString("en-US")}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare1?.values?.[0].fmv.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.values?.[0].fmv,
                      compare1?.values?.[0].fmv
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.values?.[0].fmv,
                    compare1?.values?.[0].fmv
                  )}
                </span>
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare2?.values?.[0].fmv.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.values?.[0].fmv,
                      compare2?.values?.[0].fmv
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.values?.[0].fmv,
                    compare2?.values?.[0].fmv
                  )}
                </span>
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare3?.values?.[0].fmv.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.values?.[0].fmv,
                      compare3?.values?.[0].fmv
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.values?.[0].fmv,
                    compare3?.values?.[0].fmv
                  )}
                </span>
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare4?.values?.[0].fmv.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.values?.[0].fmv,
                      compare4?.values?.[0].fmv
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.values?.[0].fmv,
                    compare4?.values?.[0].fmv
                  )}
                </span>
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare5?.values?.[0].fmv.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.values?.[0].fmv,
                      compare5?.values?.[0].fmv
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.values?.[0].fmv,
                    compare5?.values?.[0].fmv
                  )}
                </span>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Sale Price
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                ${subject?.sales?.[0].sale_price.toLocaleString("en-US")}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare1?.sales?.[0].sale_price.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_price,
                      compare1?.sales?.[0].sale_price
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.sales?.[0].sale_price,
                    compare1?.sales?.[0].sale_price
                  )}
                </span>
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare2?.sales?.[0].sale_price.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_price,
                      compare2?.sales?.[0].sale_price
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.sales?.[0].sale_price,
                    compare2?.sales?.[0].sale_price
                  )}
                </span>
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare3?.sales?.[0].sale_price.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_price,
                      compare3?.sales?.[0].sale_price
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.sales?.[0].sale_price,
                    compare3?.sales?.[0].sale_price
                  )}
                </span>
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare4?.sales?.[0].sale_price.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_price,
                      compare4?.sales?.[0].sale_price
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.sales?.[0].sale_price,
                    compare4?.sales?.[0].sale_price
                  )}
                </span>
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ${compare5?.sales?.[0].sale_price.toLocaleString("en-US")}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_price,
                      compare5?.sales?.[0].sale_price
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.sales?.[0].sale_price,
                    compare5?.sales?.[0].sale_price
                  )}
                </span>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Sale Date
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.sales?.[0].sale_date}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.sales?.[0].sale_date}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_date,
                      compare1?.sales?.[0].sale_date
                    ),
                  }}
                >
                  {getMonthDiff(
                    subject?.sales?.[0].sale_date,
                    compare1?.sales?.[0].sale_date
                  )}
                </span>
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.sales?.[0].sale_date}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_date,
                      compare2?.sales?.[0].sale_date
                    ),
                  }}
                >
                  {getMonthDiff(
                    subject?.sales?.[0].sale_date,
                    compare2?.sales?.[0].sale_date
                  )}
                </span>
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.sales?.[0].sale_date}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_date,
                      compare3?.sales?.[0].sale_date
                    ),
                  }}
                >
                  {getMonthDiff(
                    subject?.sales?.[0].sale_date,
                    compare3?.sales?.[0].sale_date
                  )}
                </span>
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.sales?.[0].sale_date}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_date,
                      compare4?.sales?.[0].sale_date
                    ),
                  }}
                >
                  {getMonthDiff(
                    subject?.sales?.[0].sale_date,
                    compare4?.sales?.[0].sale_date
                  )}
                </span>
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.sales?.[0].sale_date}
                <span
                  style={{
                    color: getColor(
                      subject?.sales?.[0].sale_date,
                      compare5?.sales?.[0].sale_date
                    ),
                  }}
                >
                  {getMonthDiff(
                    subject?.sales?.[0].sale_date,
                    compare5?.sales?.[0].sale_date
                  )}
                </span>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Land Unit
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {calLandUnit(subject)}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {calLandUnit(compare1)}
                <span
                  style={{
                    color: getLandColor(subject.land_size, compare1.land_size),
                  }}
                >
                  {getLandDiff(subject.land_size, compare1.land_size)}
                </span>
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {calLandUnit(compare2)}
                <span
                  style={{
                    color: getLandColor(subject.land_size, compare2.land_size),
                  }}
                >
                  {getLandDiff(subject.land_size, compare2.land_size)}
                </span>
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {calLandUnit(compare3)}
                <span
                  style={{
                    color: getLandColor(subject.land_size, compare3.land_size),
                  }}
                >
                  {getLandDiff(subject.land_size, compare3.land_size)}
                </span>
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {calLandUnit(compare4)}
                <span
                  style={{
                    color: getLandColor(subject.land_size, compare4.land_size),
                  }}
                >
                  {getLandDiff(subject.land_size, compare4.land_size)}
                </span>
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {calLandUnit(compare5)}
                <span
                  style={{
                    color: getLandColor(subject.land_size, compare5.land_size),
                  }}
                >
                  {getLandDiff(subject.land_size, compare5.land_size)}
                </span>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Living Area
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].area.toLocaleString("en-US")} Sqft
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].area.toLocaleString("en-US")} Sqft
                <span
                  style={{
                    color: getColor(
                      subject?.resis?.[0].area,
                      compare1?.resis?.[0].area
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.resis?.[0].area,
                    compare1?.resis?.[0].area
                  )}
                </span>
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].area.toLocaleString("en-US")} Sqft
                <span
                  style={{
                    color: getColor(
                      subject?.resis?.[0].area,
                      compare2?.resis?.[0].area
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.resis?.[0].area,
                    compare2?.resis?.[0].area
                  )}
                </span>
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].area.toLocaleString("en-US")} Sqft
                <span
                  style={{
                    color: getColor(
                      subject?.resis?.[0].area,
                      compare3?.resis?.[0].area
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.resis?.[0].area,
                    compare3?.resis?.[0].area
                  )}
                </span>
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].area.toLocaleString("en-US")} Sqft
                <span
                  style={{
                    color: getColor(
                      subject?.resis?.[0].area,
                      compare4?.resis?.[0].area
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.resis?.[0].area,
                    compare4?.resis?.[0].area
                  )}
                </span>
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].area.toLocaleString("en-US")} Sqft
                <span
                  style={{
                    color: getColor(
                      subject?.resis?.[0].area,
                      compare5?.resis?.[0].area
                    ),
                  }}
                >
                  {getValueDiff(
                    subject?.resis?.[0].area,
                    compare5?.resis?.[0].area
                  )}
                </span>
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Design
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].design}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].design}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].design}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].design}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].design}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].design}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Quality
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].quality}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].quality}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].quality}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].quality}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].quality}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].quality}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Condition
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].condition}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].condition}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].condition}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].condition}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].condition}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].condition}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Year Built
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].year_built}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].year_built}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].year_built}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].year_built}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].year_built}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].year_built}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Effective Year
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].effective_year}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].effective_year}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].effective_year}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].effective_year}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].effective_year}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].effective_year}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Beds
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].beds}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].beds}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].beds}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].beds}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].beds}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].beds}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Baths
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].baths}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].baths}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].baths}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].baths}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].baths}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].baths}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Rooms
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].rooms}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].rooms}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].rooms}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].rooms}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].rooms}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].rooms}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Heat
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].heat}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].heat}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].heat}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].heat}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].heat}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].heat}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Air
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].air}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].air}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].air}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].air}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].air}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].air}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Fireplace
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                {subject?.resis?.[0].fireplace}
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare1?.resis?.[0].fireplace}
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare2?.resis?.[0].fireplace}
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare3?.resis?.[0].fireplace}
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare4?.resis?.[0].fireplace}
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                {compare5?.resis?.[0].fireplace}
              </TableCell>
            )}
          </TableRow>
          {additions.map((a, i) => (
            <TableRow key={i}>
              <TableCell sx={headerStyle} size="small" align="center">
                Addition {a}
              </TableCell>
              {subject && (
                <TableCell sx={subjectStyle} size="small" align="center">
                  {subject?.miscs?.[i]?.code
                    ? subject.miscs[i].code +
                      (subject?.miscs?.[i]?.area
                        ? " (" +
                          subject.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
              {compare1 && (
                <TableCell sx={bodyStyle} size="small" align="center">
                  {compare1?.miscs?.[i]?.code
                    ? compare1.miscs[i].code +
                      (compare1?.miscs?.[i]?.area
                        ? " (" +
                          compare1.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
              {compare2 && (
                <TableCell sx={bodyStyle} size="small" align="center">
                  {compare2?.miscs?.[i]?.code
                    ? compare2.miscs[i].code +
                      (compare2?.miscs?.[i]?.area
                        ? " (" +
                          compare2.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
              {compare3 && (
                <TableCell sx={bodyStyle} size="small" align="center">
                  {compare3?.miscs?.[i]?.code
                    ? compare3.miscs[i].code +
                      (compare3?.miscs?.[i]?.area
                        ? " (" +
                          compare3.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
              {compare4 && (
                <TableCell sx={bodyStyle} size="small" align="center">
                  {compare4?.miscs?.[i]?.code
                    ? compare4.miscs[i].code +
                      (compare4?.miscs?.[i]?.area
                        ? " (" +
                          compare4.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
              {compare5 && (
                <TableCell sx={bodyStyle} size="small" align="center">
                  {compare5?.miscs?.[i]?.code
                    ? compare5.miscs[i].code +
                      (compare5?.miscs?.[i]?.area
                        ? " (" +
                          compare5.miscs[i].area.toLocaleString("en-US") +
                          ")"
                        : "")
                    : ""}
                </TableCell>
              )}
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={headerStyle} size="small" align="center">
              Data Source
            </TableCell>
            {subject && (
              <TableCell sx={subjectStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
            {compare1 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
            {compare2 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
            {compare3 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
            {compare4 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
            {compare5 && (
              <TableCell sx={bodyStyle} size="small" align="center">
                ASSESSORINFO.ORG
              </TableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompareDetail;
