import React from "react";
import { Paper, Grid, Container } from "@mui/material";
import classes from "./styles.module.css";

const About = () => {
  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={3}>
        <Grid container justifyContent="center" sx={{ mb: 5 }}>
          <Grid item xs={11}>
            <br />
            <strong>How much is my home worth?</strong>
            <p>
              Home value is the market value determined by your local
              jurisdiction, which is the value derived using mass appraisal
              practices.
            </p>
            <br />
            <strong>How Is Your Property Value Calculated?</strong>
            <p>
              The market value assigned is determined by using Mass Appraisal
              Practices.
              <br />
              Market value for assessment purposes is generally determined
              through the application of mass appraisal techniques. Mass
              appraisal is the process of valuing a group of properties as of a
              given date and using common data, standardized methods, and
              statistical testing. To determine a parcel's value, assessing
              officers must rely upon valuation equations, tables, and schedules
              developed through mathematical analysis of market data. Values for
              individual parcels should not be based solely on the sale price of
              a property; rather, valuation schedules and models should be
              consistently applied to property data that are correct, complete,
              and up-to-date.
            </p>
            <br />
            <strong>About Home Ownership / Property Ownership.</strong>
            <p>
              Public tax assessor records brought to the public for free. The
              data includes but not limited to property ownership, legal
              descriptions, property characteristics, owner mailing address,
              property address, and values.
              <br />
              The data is free to view and download, which gives you the ability
              to create a mailing list. This information can be used for and by
              appraisers, realtors, banks, law enforcement, private industry and
              governmental agencies, local neighborhood watch, economic
              development, right-of-way assignments, vacant land to lease, etc.
            </p>
            <br />
            <strong>About Property Characteristics Details.</strong>
            <p>
              Property characteristics and conditions are collected by the local
              tax assessor and used to determine the property value. These
              characteristics include bed, bath, and room count. Additionally,
              the square footage, interior and exterior features, and land
              details including the parcel boundary.
            </p>
            <br />
            <strong>
              About Damage Assessment / Property Value Analysis / Property Sale
              Analysis.
            </strong>
            <p>
              Property ownership, property values, and parcel boundaries can be
              exported for free to perform damage assessments.
              <br />
              Furthermore, detailed charts and graphs are available.
            </p>
            <br />
            <strong>
              About Full Property Sales, Home Sales, and Vacant Land Sales.
            </strong>
            <p>
              AssessorInfo.org property sales information includes both private
              and public sale transactions.
            </p>
            <br />
            <strong>What does that mean and why is that important?</strong>
            <p>
              This means the sale price for every property sold can be found for
              a sales comparable, not just the property found in MLS. Exception
              of non-disclosure states.
            </p>
            <br />
            <strong>Who Is The Software Made For?</strong>
            <p>
              We designed the software and data for free use to everyone. Public
              data made available to all to assist in research, property
              analysis, determine property ownership, and full disclosure of
              property information.
            </p>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default About;
