import React, { useRef, useState } from "react";
import { Paper, Grid, Container } from "@mui/material";
import classes from "./styles.module.css";

const Privacy = () => {
  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={3}></Paper>
    </Container>
  );
};

export default Privacy;
