import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import classes from "./styles.module.css";
import { updateProfile } from "../../api/profile";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  curPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const Form = () => {
  const user = JSON.parse(localStorage.getItem("assessorinfoprofile"));

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);
  const [form, setForm] = useState(initialState);
  const [success, setSuccess] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(null);
    if (user?.result) {
      updateProfile(form).then((data) => {
        user.result.password = data;
        localStorage.setItem("assessorinfoprofile", JSON.stringify(user));
        setForm({
          ...form,
          curPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
        setSuccess("Password Updated");
      });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (user?.result) {
      setForm({
        firstName: user.result.first_name,
        lastName: user.result.last_name,
        email: user.result.email,
        curPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      {user?.result ? (
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar} sx={{ bgcolor: "#fff" }}>
            <LockOutlinedIcon color="primary" />
          </Avatar>
          <Typography component="h1" variant="h6">
            Update Profile
          </Typography>
          <Grid container spacing={2}>
            {success && (
              <Grid item xs={12}>
                <Alert
                  severity="success"
                  onClose={() => {
                    setSuccess(null);
                  }}
                >
                  {success}
                </Alert>
              </Grid>
            )}
            <Grid item xs={6}>
              <TextField
                name="firstName"
                label="First Name *"
                variant="outlined"
                value={form.firstName}
                sx={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="lastName"
                label="Last Name *"
                variant="outlined"
                value={form.lastName}
                sx={{ width: "100%" }}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email Address *"
                variant="outlined"
                value={form.email}
                sx={{ width: "100%" }}
                disabled
              />
            </Grid>
          </Grid>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="curPassword"
                  label="Current Password"
                  variant="outlined"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  value={form.curPassword}
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="newPassword"
                  label="New Password"
                  variant="outlined"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  value={form.newPassword}
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirmNewPassword"
                  label="Confirm New Password"
                  variant="outlined"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  value={form.confirmNewPassword}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ marginTop: "15px" }}
            >
              Update Password
            </Button>
          </form>
        </Paper>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Form;
