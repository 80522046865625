import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Paper } from "@mui/material";
import ParcelInfo from "./ParcelInfo";
import { getParcel } from "../../actions/parcel";
import classes from "./styles.module.css";

const ParcelHome = ({ state, pid, print }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  dispatch(getParcel({ state, pid }, navigate));

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={3}>
        <ParcelInfo print={print}/>
      </Paper>
    </Container>
  );
};

export default ParcelHome;
