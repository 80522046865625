import React from "react";
import CookieConsent from "react-cookie-consent";

const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I Accept"
      cookieName="assessorinfocookieagreement"
      buttonStyle={{ color: "white", background: "#1976d2", fontSize: "15px" }}
      expires={1}
      sameSite="strict"
    >
      This website uses cookies to enhance the user experience. If you continue
      browsing, we consider that you accept our {" "}
      <a href="https://s3.amazonaws.com/assessorinfo.org/Cookie+Disclaimer.pdf" target={"_blank"} style={{color: "#1976d2"}}>Cookie Policy</a>.
    </CookieConsent>
  );
};

export default CookieBanner;
