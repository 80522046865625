import Cookies from 'js-cookie';
import { FETCH_PARCELS } from '../constants/actionTypes';
import * as api from '../api/parcels';

export const getParcels = (getParams) => async (dispatch) => {
  try {
    Cookies.set("search", JSON.stringify(getParams), {
      expires: 1,
    });

    const { data } = await api.fetchParcels(getParams);

    dispatch({ type: FETCH_PARCELS, parcels: data.parcels });
  } catch (error) {
    console.log(error.message);
  }
};