import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GoogleMapReact from "google-map-react";
import { CircularProgress } from "@mui/material";
// TODO - for location search and user current location later
// import { Grid, TextField, Button } from "@mui/material";
// import { Autocomplete } from "@react-google-maps/api";
import SearchPaper from "./SearchPaper";
import classes from "./styles.module.css";

const ParcelsMap = ({
  setBounds,
  setCoordinates,
  setZoom,
  setChildClicked,
  setIsMapLoading,
  setSearchFields,
  coordinates,
  zoom,
  searchFields,
  childClicked,
  isMapLoading,
}) => {
  const parcels = useSelector((state) => state.parcels);
  const [googleMap, setGoogleMap] = useState(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(false);

  const handleCurrentLocation = () => {
    setIsMapLoading(true);
    setSearchFields({ ...searchFields, address: "" });
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoordinates({ lat: latitude, lng: longitude });
        setZoom(14);
        setCurrentLocation(true);
        setIsMapLoading(false);
      }
    );
  };

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setGoogleMap(map);
      setGoogleMaps(maps);

      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      maps.event.clearListeners(map.data, "click");

      let geojsons = {
        type: "FeatureCollection",
        features: [],
      };
      if (parcels?.[0]) {
        parcels.map((parcel, i) => {
          let geojson = {
            type: "Feature",
            properties: {
              index: 0,
              id: 0,
              parcel_id: "",
            },
            geometry: {},
          };
          if (parcel.geometry) {
            geojson["properties"]["index"] = i;
            geojson["properties"]["id"] = parcel.id;
            geojson["properties"]["parcel_id"] = parcel.parcel_id;
            geojson["geometry"] = JSON.parse(parcel.geometry);
            geojsons["features"].push(geojson);
          }
        });
        map.data.addGeoJson(geojsons);
        map.data.setStyle({
          fillColor: "blue",
          strokeWeight: 1,
        });
        map.data.addListener("click", (event) => {
          setChildClicked(event.feature.getProperty("index"));
        });
        map.addListener("click", (event) => {
          setChildClicked(null);
        });

        if (currentLocation) {
          setCurrentLocation(false);
        } else {
          let bounds = new maps.LatLngBounds();
          map.data.forEach((feature) => {
            feature.getGeometry().forEachLatLng(function (latlng) {
              bounds.extend(latlng);
            });
          });
          map.fitBounds(bounds);
        }
      }
    }
  };

  useEffect(() => {
    handleApiLoaded(googleMap, googleMaps);
  }, [parcels]);

  const handleChange = (e) => {
    setSearchFields({ ...searchFields, [e.target.name]: e.target.value });
  };

  const onLoad = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete.getPlace().geometry) {
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      setCoordinates({ lat, lng });
      setZoom(14);
    }
  };

  return (
    <div className={classes.mapContainer}>
      {/* TODO - Adding location search and user current location later
      <Grid container direction="row" spacing={2} width="100%" sx={{ mb: 1.5 }}>
        <Grid item xs={7}>
          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
            <TextField
              name="address"
              size="small"
              label="Enter a Location"
              variant="outlined"
              value={searchFields.address}
              sx={{ mt: 1, width: "100%" }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </Autocomplete>
        </Grid>
        <Grid item xs={5}>
          <Button
            size="small"
            variant="outlined"
            sx={{ mt: 1.5, ml: 1 }}
            onClick={handleCurrentLocation}
          >
            User Location
          </Button>
        </Grid>
      </Grid> */}
      {isMapLoading ? (
        <div className={classes.loading}>
          <CircularProgress size="5rem" />
        </div>
      ) : (
        <>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            center={coordinates}
            zoom={zoom}
            margin={[50, 50, 50, 50]}
            options={(maps) => ({
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
              },
            })}
            onChange={(e) => {
              setCoordinates({ lat: e.center.lat, lng: e.center.lng });
              setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
              setZoom(e.zoom);
            }}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {parcels && parcels.length && childClicked !== null && (
              <SearchPaper
                lat={Number(parcels[childClicked].lat)}
                lng={Number(parcels[childClicked].lng)}
                parcel={parcels[childClicked]}
                className={classes.paper}
                setChildClicked={setChildClicked}
              ></SearchPaper>
            )}
          </GoogleMapReact>
        </>
      )}
    </div>
  );
};

export default ParcelsMap;
