import { FETCH_PARCEL } from "../constants/actionTypes";

export default (parcel = null, action) => {
  switch (action.type) {
    case FETCH_PARCEL:
      return action.parcel;
    default:
      return parcel;
  }
};
