import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import SearchPaper from "../search/SearchPaper";
import classes from "../search/styles.module.css";

const ParcelMap = ({ parcel }) => {
  const [googleMap, setGoogleMap] = useState(null);
  const [googleMaps, setGoogleMaps] = useState(null);
  const [childClicked, setChildClicked] = useState(null);

  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      setGoogleMap(map);
      setGoogleMaps(maps);

      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      maps.event.clearListeners(map.data, "click");

      let geojsons = {
        type: "FeatureCollection",
        features: [],
      };
      if (parcel?.arounds) {
        parcel.arounds.map((around, i) => {
          let geojson = {
            type: "Feature",
            properties: {
              index: 0,
              id: 0,
              parcel_id: "",
            },
            geometry: {},
          };
          if (around.geometry) {
            geojson["properties"]["index"] = i;
            geojson["properties"]["id"] = around.id;
            geojson["properties"]["parcel_id"] = around.parcel_id;
            geojson["geometry"] = around.geometry;

            geojsons["features"].push(geojson);
          }
        });
        map.data.addGeoJson(geojsons);
        map.data.setStyle((feature) => {
          let id = feature.getProperty("id");
          let color = "gray";
          if (id === parcel?.parcel?.id) {
            color = "blue";
          }
          return {
            fillColor: color,
            strokeWeight: 1,
          };
        });
        map.data.addListener("click", (event) => {
          setChildClicked(event.feature.getProperty("index"));
        });
        map.addListener("click", (event) => {
          setChildClicked(null);
        });
      }
    }
  };

  useEffect(() => {
    handleApiLoaded(googleMap, googleMaps);
  }, [parcel]);

  return (
    <>
      {parcel?.arounds ? (
        <div className={classes.parcelMapContainer}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            }}
            center={{ lat: parcel?.shape?.lat, lng: parcel?.shape?.lng }}
            defaultZoom={16}
            margin={[50, 50, 50, 50]}
            options={(maps) => ({
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.TOP_LEFT,
                mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
              },
            })}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            {parcel?.arounds && childClicked !== null && (
              <SearchPaper
                lat={Number(parcel.arounds[childClicked].lat)}
                lng={Number(parcel.arounds[childClicked].lng)}
                parcel={parcel.arounds[childClicked]}
                className={classes.paper}
              ></SearchPaper>
            )}
          </GoogleMapReact>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ParcelMap;
