import React from "react";
import { Modal, Box, Grid, Fade, Backdrop, Link, Button } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import ParcelHome from "../parcel/ParcelHome";

const SearchModal = ({ setOpenModal, openModal, state, pid }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={openModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "100vh",
            width: "90vw",
            bgcolor: "background.paper",
            border: "0px",
            boxShadow: 24,
            p: 4,
            overflow: "scroll",
          }}
        >
          <Grid container sx={{ justifyContent: "flex-end" }}>
            <Link
              href={`/parcel/${JSON.stringify({ state: state, id: pid })}`}
              target="_blank"
              title="Show more in a new Window"
            >
              <MoreHorizOutlinedIcon cursor="pointer" sx={{ mr: 5 }} />
            </Link>
            <CloseOutlinedIcon
              color="warning"
              cursor="pointer"
              onClick={handleClose}
            />
          </Grid>
          <ParcelHome state={state} pid={pid} print={false}/>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SearchModal;
