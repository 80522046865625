import React from "react";
import { useDispatch } from "react-redux";
import { Container, Paper } from "@mui/material";
import CompareInfo from "./CompareInfo";
import { viewCompares } from "../../actions/compares";
import classes from "./styles.module.css";

const CompareHome = ({ user_id, state }) => {
  const dispatch = useDispatch();
  dispatch(viewCompares(user_id, state));

  return (
    <Container component="main" maxWidth="xl">
      <Paper className={classes.paper} elevation={3}>
        <CompareInfo />
      </Paper>
    </Container>
  );
};

export default CompareHome;
