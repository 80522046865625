import React from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Grid, Button } from "@mui/material";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";

const CompareToolBar = (props) => {
  return (
    <GridToolbarContainer>
      <Grid container>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarColumnsButton />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <GridToolbarExport csvOptions={{ fileName: "AssessorInfoExport" }} />
        </Grid>
        <Grid item xs={6} sm={2.5}>
          <Button
            size="small"
            startIcon={<CompareArrowsOutlinedIcon />}
            href={`/compare/${JSON.stringify({
              state: props.state,
            })}`}
            target="_blank"
          >
            COMPARE
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default CompareToolBar;
