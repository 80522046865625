import React from "react";
import { Paper, Typography } from "@mui/material";
import classes from "./styles.module.css";

const SearchPaper = (props) => {
  return (
    <div>
      <Paper className={props.className} elevation={3}>
        <Typography
          variant="button"
          className={classes.paperContent}
        >{"id: "}
          {props.parcel.parcel_id}
        </Typography>
        <Typography
          variant="button"
          className={classes.paperContent}
          color="textSecondary"
        >{"addr.: "}
          {props.parcel.address}
        </Typography>
        <Typography
          variant="button"
          className={classes.paperContent}
          color="textSecondary"
        >{"owner: "}
          {props.parcel.owner_name}
        </Typography>
        <Typography
          variant="button"
          className={classes.paperContent}
          color="textSecondary"
        >{"fmv: $"}
          {props.parcel.fmv.toLocaleString("en-US")}
        </Typography>
      </Paper>
    </div>
  );
};

export default SearchPaper;
