import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { getOptions } from "../../api/options";
import classes from "./styles.module.css";

const HomeSearch = () => {
  const [states, setStates] = useState([]);
  const [state, setState] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getOptions("state").then((data) => {
      setStates(data);
    });
  }, []);

  const handleChange = (event) => {
    setState(event.target.value);
    navigate(`/search/${JSON.stringify({ state: event.target.value })}`);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}  className={classes.grid}>
        <FormControl className={classes.form}>
          <InputLabel id="states-label">Select a State...</InputLabel>
          <Select
            labelId="states-label"
            id="states"
            value={state}
            label="Select a State..."
            options={states}
            onChange={handleChange}
          >
            {states?.map((state) => (
              <MenuItem key={state.state_code} value={state.state_code}>
                {state.state_name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Please select a state first</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default HomeSearch;
