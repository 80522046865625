import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import decode from "jwt-decode";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import PersonIcon from "@mui/icons-material/Person";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  AppBar,
  Button,
  Box,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Container,
  Avatar,
  Divider,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import Cookies from "js-cookie";
import classes from "./styles.module.css";
import { LOGOUT } from "../../constants/actionTypes";

const HomeHeader = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("assessorinfoprofile"))
  );
  const searchCookies = Cookies.get("search");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [menuEl, setMenuEl] = useState(null);
  const [accountEl, setAccountEl] = useState(null);
  const menuOpen = Boolean(menuEl);
  const accountOpen = Boolean(accountEl);
  const paperProps = {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      width: 200,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  };

  const handleClickMenu = (event) => {
    setMenuEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuEl(null);
  };
  const handleClickAccount = (event) => {
    setAccountEl(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAccountEl(null);
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    navigate("/");
    setUser(null);
  };

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("assessorinfoprofile")));
  }, [location]);

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            className={classes.heading}
            noWrap
            sx={{ mr: 2 }}
          >
            ASSESSORINFO
          </Typography>

          <Box sx={{ flexGrow: 1 }}>
            <React.Fragment>
              <Box>
                <Tooltip title="Menu">
                  <IconButton
                    onClick={handleClickMenu}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={menuOpen ? "site-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? "true" : undefined}
                  >
                    <MenuIcon sx={{ color: "white" }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Menu
                anchorEl={menuEl}
                id="site-menu"
                open={menuOpen}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={paperProps}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem component={Link} to="/">
                  <ListItemIcon>
                    <HomeIcon fontSize="small" />
                  </ListItemIcon>
                  HOME
                </MenuItem>
                {location?.pathname.includes("/search/") && <Divider />}
                {location?.pathname.includes("/search/") && (
                  <MenuItem component={Link} to="/">
                    <ListItemIcon>
                      <EditLocationAltIcon fontSize="small" />
                    </ListItemIcon>
                    CHANGE STATE
                  </MenuItem>
                )}
                {searchCookies && <Divider />}
                {searchCookies && (
                  <MenuItem component={Link} to={"/search/" + searchCookies}>
                    <ListItemIcon>
                      <SearchIcon fontSize="small" />
                    </ListItemIcon>
                    LAST SEARCH
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user?.result ? (
              <React.Fragment>
                <Box>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClickAccount}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={accountOpen ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={accountOpen ? "true" : undefined}
                    >
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                          color: "#1976d2",
                          bgcolor: "white",
                        }}
                      >
                        {user.result.first_name.substring(0, 1)}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={accountEl}
                  id="account-menu"
                  open={accountOpen}
                  onClose={handleCloseAccount}
                  onClick={handleCloseAccount}
                  PaperProps={paperProps}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem component={Link} to="/profile">
                    <ListItemIcon>
                      <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    PROFILE
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    sx={{ width: "100%" }}
                    component={Button}
                    onClick={logout}
                  >
                    <ListItemIcon>
                      <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            ) : (
              <div>
                <Button
                  sx={{ display: "flex" }}
                  variant="text"
                  component={Link}
                  to="/auth"
                  endIcon={<LoginIcon />}
                  style={{ color: "#fff" }}
                >
                  SIGN IN
                </Button>
              </div>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default HomeHeader;
